import i18next from 'i18next'
import moment from 'moment'
import en from './configFiles/translations/en'
import fr from './configFiles/translations/fr'
import 'moment/locale/fr'

let languages = []

if (!_.isEmpty(en)) languages.push('en')
if (!_.isEmpty(fr)) languages.push('fr')

i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  resources: {
    en: { translation: en },
    fr: { translation: fr }
  }
})

export const translations = (key, options) => i18next.t(key, options)
export const getCurrentLanguage = () => i18next.language || 'en'
export const getAvailableLanguages = () => languages
export const changeLanguage = lang => {
  moment.locale([lang, 'en'])
  i18next.changeLanguage(lang)
}
