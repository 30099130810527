// Each form input
import InputComponent from './Input'
import DropdownComponent from './Dropdown'
import CheckboxComponent from './Checkbox'
import ChecklistComponent from './Checklist'
import RadiolistComponent from './Radiolist'
import CounterComponent from './Counter'
import PhoneNumberComponent from './PhoneNumber'
import DateComponent from './Date'
import TimeComponent from './Time'
import DateTimeComponent from './DateTime'
import PasswordConfirmComponent from './PasswordConfirm'
import SelectUserComponent from './SelectUser'
import SelectCustomerComponent from './SelectCustomer'
import TimePickerComponent from './TimePicker'
import DatePickerComponent from './DatePicker'
import DateTimePickerComponent from './DateTimePicker'
import ProductCarouselComponent from './ProductCarousel'
import BirthdayComponent from './Birthday'
import YesNoComponent from './YesNo'
import IconCheckboxComponent from './IconCheckbox'
import AutoCompleteComponent from './AutoComplete'

// Connects field to redux-forms
import FieldWrapper from './Wrappers/FieldWrapper'

// Wrap and export fields
export const Input = FieldWrapper(InputComponent)
export const Dropdown = FieldWrapper(DropdownComponent)
export const Checkbox = FieldWrapper(CheckboxComponent)
export const Checklist = FieldWrapper(ChecklistComponent)
export const Radiolist = FieldWrapper(RadiolistComponent)
export const Counter = FieldWrapper(CounterComponent)
export const PhoneNumber = FieldWrapper(PhoneNumberComponent)
export const Date = FieldWrapper(DateComponent)
export const Time = FieldWrapper(TimeComponent)
export const DateTime = FieldWrapper(DateTimeComponent)
export const PasswordConfirm = FieldWrapper(PasswordConfirmComponent)
export const SelectUser = FieldWrapper(SelectUserComponent)
export const SelectCustomer = FieldWrapper(SelectCustomerComponent)
export const TimePicker = FieldWrapper(TimePickerComponent)
export const DatePicker = FieldWrapper(DatePickerComponent)
export const DateTimePicker = FieldWrapper(DateTimePickerComponent)
export const ProductCarousel = FieldWrapper(ProductCarouselComponent)
export const Birthday = FieldWrapper(BirthdayComponent)
export const YesNo = FieldWrapper(YesNoComponent)
export const IconCheckbox = FieldWrapper(IconCheckboxComponent)
export const AutoComplete = FieldWrapper(AutoCompleteComponent)
