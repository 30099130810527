import { connect } from 'react-redux'

import { selectors as followingSelectors } from '../../../../../../store/modules/following'
import { selectors as customerDetailsSelectors } from '../../../../../../store/modules/customerDetails'

import RecipientsSearchModal from './RecipientsSearchModal'

const mapStateToProps = state => {
  return {
    isLoading: customerDetailsSelectors.getInitialCustomerDetailIsLoading(state),
    following: followingSelectors.getFollowingCustomersWithoutAnonymised(state)
  }
}

export default connect(mapStateToProps)(RecipientsSearchModal)
