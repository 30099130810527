import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter, matchPath } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Header from '../../Header'
import RootMenu from '../RootMenu'
import BasketMenu from '../../Basket'
import BasketCounter from '../../Basket/BasketCounter'
import Modal from '../../../components/Modal'
import NetworkNotification from '../../../components/NetworkNotification'
import ToastContainer from '../../../components/ToastContainer'
import LockScreen from '../../LockScreen'
import VCAudioContainer from '../../ConsultationsV2/VCAudioContainer'

import { apps, translations, a11y, theme } from '../../../config'
import { env } from '../../../config/environments'
import style from './style'

const MainContainer = styled.main`
  ${({ networkConnectivity, isUnsecuredPage }) => `
    ${isUnsecuredPage && `
      padding-top: calc(${theme[`headerHeight${networkConnectivity ? '' : 'Offline'}`]}px + env(safe-area-inset-top)) !important;
    `}
  `}
`

class RootScreen extends Component {
  componentDidMount () {
    this.props.fetchConfig()
  }
  componentDidUpdate (prevProps) {
    if (
      this.props.location.hash &&
      this.props.location.hash !== prevProps.location.path
    ) {
      setTimeout(
        () => {
          const element = document.getElementById(
            this.props.location.hash.replace('#', '')
          )
          if (element) {
            element.scrollIntoView()
          }
        },
        0
      )
      return
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      setTimeout(
        () => {
          window.scrollTo(0, 50)
          setTimeout(() => window.scrollTo(0, 0), 1)
        },
        1
      )
    }
  }

  render () {
    const { isUnsecuredPage, isAppAllowedForRole, children, location, classes, networkConnectivity, isLoggedIn } = this.props
    const screenContainerClassName = isUnsecuredPage ? classes.screenContainer : undefined
    const isCheckout = !matchPath(location.pathname, { path: '/checkout' })
    const isReferral = !matchPath(location.pathname, { path: '/referral' })
    const basketEnabled = isLoggedIn && apps.CATALOG
    const showBasket = (isCheckout || isReferral) && basketEnabled

    return (
      <div className={classes.container}>
        <a href={`#${a11y.mainContentId}`} className={classes.skipToMainContent}>Skip to main content</a>
        <Modal index={0} />
        <Modal index={1} />
        <Modal index={2} />
        <Modal index={3} />
        <ToastContainer />
        {env !== 'production' && <div className={classes.environment}>environment: {env}</div>}
        {isUnsecuredPage && [
          <Header
            key={'header'}
            rightElement={showBasket && BasketCounter}
            networkNotification={<NetworkNotification />}
          />,
          <RootMenu key={'rootMenu'} />,
          <BasketMenu key={'basketMenu'} />
        ]}
        <MainContainer className={screenContainerClassName} id={a11y.mainContentId} isUnsecuredPage={isUnsecuredPage} networkConnectivity={networkConnectivity}>
          {isAppAllowedForRole ? (
            children
          ) : (
            <div className={classes.noPermissionMessage}>{translations('You do not have permission to view this screen')}</div>
          )}
        </MainContainer>
        <LockScreen location={location} />
        <VCAudioContainer />
      </div>
    )
  }
}

RootScreen.propTypes = {
  fetchConfig: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default withRouter(withStyles(style)(RootScreen))
