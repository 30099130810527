import * as validators from '../../../validators'
import personalSchema from '../../../components/Form/commonSchemas/customerPersonalDetails'
import addressSchema from '../../../components/Form/commonSchemas/customerAddressDetails'
import { getAppConfig } from '../../../config'

const smallTextLengthValidator = validators.maxLength(50)

export const personal = {
  schema: personalSchema,
  layout: [
    ['title', 'firstName'],
    ['lastName', 'telephone'],
    ['email', 'dob'],
    ['gender', 'country']
  ]
}

export const address = {
  schema: addressSchema.map(item => {
    return {
      ...item,
      props: {
        ...item.props,
        label: item.props.label
      }
    }
  }),
  layout: [
    ['title'],
    ['firstName', 'lastName'],
    ['companyName', 'telephone'],
    ['address1', 'address2'],
    ['city', 'county'],
    ['postCode', 'country']
  ]
}

export const loyalty = {
  schema: [{
    id: 'loyaltyTier',
    field: 'Input',
    props: {
      label: 'Loyalty Tier',
      name: 'loyaltyTier',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'lifetimeSpend',
    field: 'Input',
    props: {
      label: 'Lifetime Spend',
      name: 'lifetimeSpend',
      editable: false
    }
  }],
  layout: [
    ['lifetimeSpend', 'loyaltyTier']
  ]
}

const customerFieldsConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'detailsFields')

const customerSchema = (customerFieldsConfig) && customerFieldsConfig.map(item => {
  const { id, field, validate, ...rest } = item
  return {
    id,
    field,
    props: {
      ...rest,
      name: id,
      validate: validators.generateSchemaValidationFromConfig(validate),
      label: item.label
    }
  }
})

export const customer = {
  schema: customerSchema,
  layout: []
}

export const sizing = {
  schema: [{
    id: 'coatAndJackets',
    field: 'Input',
    props: {
      label: 'Coats / Jackets',
      name: 'coatAndJackets',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'blousesAndShirts',
    field: 'Input',
    props: {
      label: 'Blouses / Shirts',
      name: 'blousesAndShirts',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'trousers',
    field: 'Input',
    props: {
      label: 'Trousers',
      name: 'trousers',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'dress',
    field: 'Input',
    props: {
      label: 'Dress',
      name: 'dress',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'skirt',
    field: 'Input',
    props: {
      label: 'Skirt',
      name: 'skirt',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'shoe',
    field: 'Input',
    props: {
      label: 'Shoe',
      name: 'shoe',
      validate: [smallTextLengthValidator]
    }
  }],
  layout: [
    ['coatAndJackets', 'blousesAndShirts'],
    ['trousers', 'dress'],
    ['skirt', 'shoe']
  ]
}
