import _ from 'lodash'
import { getFullName, formatDate } from '../../../../../helpers'
import { getCustomerFullName } from '../../../../../helpers/getFullName'
import currencyFormatter from '../../../../../formatters/currencyFormatter'
import { translations } from '../../../../../config'

export const getColumns = () => ([
  { key: 'date', title: translations('Orders Table Header Date'), cellWidth: 1 },
  { key: 'orderNumber', title: translations('Orders Table Header Order Number'), cellWidth: 1, clickHandler: true },
  { key: 'items', title: translations('Orders Table Header Items'), cellWidth: 1 },
  { key: 'total', title: translations('Orders Table Header Total'), cellWidth: 1 },
  { key: 'customer', title: translations('Orders Table Header Customer'), cellWidth: 2 },
  { key: 'user', title: translations('Orders Table Header User'), cellWidth: 2 },
  { key: 'store', title: translations('Orders Table Header Store'), cellWidth: 2 },
  { key: 'status', title: translations('Orders Table Header Status'), cellWidth: 2 }
])

export const getStatus = (status) => {
  const statuses = {
    'Edit': 'Edited Order',
    'Partial Refund': 'Partial Refund',
    'Full Refund': 'Fully Refunded'
  }
  return statuses[status] || status
}

export const getFormattedOrderRow = (order) => {
  const status = _.startCase(_.get(order, 'status'))
  const userFullName = getFullName(order.user)
  const customerFullName = getCustomerFullName(order.customer)

  return {
    date: formatDate(order.orderDate),
    orderNumber: order.orderNumber,
    items: _.get(order, 'products.length'),
    total: currencyFormatter.format(order.total),
    customer: customerFullName || translations('Unknown'),
    user: userFullName || translations('Unknown'),
    store: _.get(order, 'store.name'),
    status: getStatus(status)
  }
}
