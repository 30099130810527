import { countries } from '../../../config'

export default [{
  id: 'address1',
  field: 'Input',
  props: {
    label: 'Address Line One',
    name: `address.address1`,
    required: true,
    shrink: true
  }
}, {
  id: 'address2',
  field: 'Input',
  props: {
    label: 'Address Line Two',
    name: `address.address2`,
    required: false,
    shrink: true
  }
}, {
  id: 'city',
  field: 'Input',
  props: {
    label: 'Town',
    name: `address.city`,
    required: true,
    shrink: true
  }
}, {
  id: 'county',
  field: 'Input',
  props: {
    label: 'County',
    name: `address.county`,
    required: false,
    shrink: true
  }
}, {
  id: 'country',
  field: 'Dropdown',
  props: {
    label: 'Country',
    name: `address.country`,
    required: true,
    options: countries,
    shrink: true
  }
}, {
  id: 'postCode',
  field: 'Input',
  props: {
    label: 'Customer Postcode',
    name: `address.postCode`,
    required: true,
    shrink: true
  }
}]
