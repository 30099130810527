import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import ReportChart from '../../../Reporting/ReportChart'
import { slugify } from '../../../../helpers'
import { customerReportingSchema as schema } from '../../../../config'
import LoccitaneReportingGraph from './LoccitaneReportingGraph'
import { selectors as authSelectors } from '../../../../store/modules/auth'

const CustomerReportingCharts = ({
  Tile,
  customers
}) => {
  return (
    schema.map((reportSchema, i) => {
      const link = reportSchema.fullListPath || `/customers/reporting/${i}-${slugify(reportSchema.title)}`
      const to = reportSchema.fullList && !(reportSchema.type === 'LIST') && link
      const seeAllLink = reportSchema.fullList && (reportSchema.type === 'LIST') && link
      const type = reportSchema.type
      const rosStoreId = useSelector(authSelectors.getUserSelectedStoreId)

      switch (type) {
        case 'LOCCITANE_REPORTING_GRAPH':
          return (
            <Tile to={to || undefined} key={`${reportSchema.title}-${i}`}>
              <LoccitaneReportingGraph rosStoreId={rosStoreId} {...reportSchema} />
            </Tile>
          )
        default:
          return (
            <Tile
              to={to || undefined}
              key={`${reportSchema.title}-${i}`}
              chartCategory={_.get(reportSchema, 'chartCategory')}
              chartOptions={_.get(reportSchema, 'chartOptions')}
            >
              <ReportChart
                items={customers}
                reportSchema={reportSchema}
                seeAllLink={seeAllLink}
              />
            </Tile>
          )
      }
    })
  )
}

export default CustomerReportingCharts
