import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'

import Order from './Order'
import currencyFormatter from '../../../formatters/currencyFormatter'
import { dateFormat, salesforceOCAPI, translations } from '../../../config'

import { formatDate } from '../../../helpers'
import analyticsService from '../../../services/analyticsService'
import { selectors as customerDetailSelectors } from '../../../store/modules/customerDetails'
import orderFormatter from '../../../formatters/orderFormatter'

const mapStateToProps = (state, ownProps) => {
  const { code, value } = ownProps.total
  const { orderDate } = ownProps
  const formattedOrder = orderFormatter.format(ownProps)
  const formattedNumberOfProducts = _.get(formattedOrder, 'numberOfPurchaseableProducts')
  const formattedItemString = formattedNumberOfProducts === 1 ? translations('item') : translations('items')
  return {
    total: currencyFormatter.format(value, code),
    numberOfProducts: `${formattedNumberOfProducts} ${formattedItemString}`,
    orderDate: formatDate(orderDate, dateFormat),
    customer: customerDetailSelectors.getCustomer(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    goToOrder: ({ dispatch, customer, orderNumber }) => () => {
      if (!salesforceOCAPI.basketEnabled) {
        analyticsService.sendCustomEvent({ type: 'orderDetailClickThrough' })
        dispatch(push(`/orders/${orderNumber}`))
      }
    }
  })
)(Order)
