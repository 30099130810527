import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class LoccitaneCustomerReportingActions {
  fetchPurchaseReporting = (params) => ({
    type: constants.FETCH_PURCHASE_REPORTING,
    promise: () => digitalStoreSdk.CCAPIcustomerReporting.fetchPurchaseReporting(params)
  })

  fetchPurchaseCountsReporting = (params) => ({
    type: constants.FETCH_PURCHASE_COUNTS_REPORTING,
    promise: () => digitalStoreSdk.CCAPIcustomerReporting.fetchPurchaseReporting(params)
  })

  fetchPurchaseReportingNextPage = (params) => ({
    type: constants.FETCH_PURCHASE_REPORTING_NEXT_PAGE,
    promise: () => digitalStoreSdk.CCAPIcustomerReporting.fetchPurchaseReporting(params)
  })

  fetchBirthdayReporting = (params) => ({
    type: constants.FETCH_BIRTHDAY_REPORTING,
    promise: () => digitalStoreSdk.CCAPIcustomerReporting.fetchBirthdayReporting(params)
  })
  
}

export default new LoccitaneCustomerReportingActions()
