import _ from 'lodash'
import React from 'react'
import Editable from '../Editable'
import { Dropdown } from '../../Fields'
import P from '../../P'

export default Editable({
  input: (props) => (
    <Dropdown {...props} />
  ),
  display: (props) => {
    const { label, value, options = [] } = props
    const storeName = _.find(options, o => o.value === value)
    let modifiedValue = value instanceof Array ? value.join(', ') : value

    if (storeName) {
      modifiedValue = storeName.label
    }

    return (
      <div>
        <P value={label} type='textButton' />
        <P value={modifiedValue} />
      </div>
    )
  }
})
