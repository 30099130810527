import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import moment from 'moment'
import { translations, dateFormat, timeFormat } from '../../../../../config'
import { DatePicker, TimePicker, Checkbox, Dropdown } from '../../../../../components/Fields'
import style from './style'

const dateTimeMin = (message) => (value, values) => {
  const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`
  const dateTime = values.date + ', ' + values.time

  if (moment(dateTime, dateAndTimeFormat).isBefore(new Date()) && !values.sendNow) {
    return translations(message)
  }
}

const dateCheck = dateTimeMin('Date is in the past')
const timeCheck = dateTimeMin(' ')

const NotificationEditorOptions = ({ classes, formValues = {} }) => {
  return (
    <div>
      <div>
        <div className={classes.dateTime}>
          <div className={classes.date}>
            <FormControl fullWidth>
              <DatePicker
                disabled={formValues.sendNow}
                label={translations('Send on')}
                name='date'
                validate={[dateCheck]}
              />
            </FormControl>
          </div>
          <div className={classes.time}>
            <FormControl fullWidth>
              <TimePicker
                disabled={formValues.sendNow}
                name='time'
                validate={[timeCheck]}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        <FormControl fullWidth>
          <Checkbox
            label={translations('Send Now')}
            name='sendNow'
          />
        </FormControl>
      </div>
      <FormControl fullWidth required>
        <Dropdown
          label={translations('Notification Type')}
          name='type'
          options={[{ label: translations('Notification Type - Task'), value: 'task' }, { label: translations('Notification Type - Standard'), value: 'standard' }]}
        />
      </FormControl>
    </div>
  )
}

export default withStyles(style)(NotificationEditorOptions)
