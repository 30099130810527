/* eslint-disable react/prop-types */

// ** IMPORTANT - READ THIS IF YOU PLAN ON CHANGING ANY OF THE TWILIO VIDEO INTEGRATION **
// https://github.com/cordova-rtc/cordova-plugin-iosrtc/issues/497

import React, { useEffect, useState, useCallback, useRef } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'

import { actions as appointmentsActions } from '../../../../store/modules/appointments'
import { selectors as currentAppointmentSelectors } from '../../../../store/modules/currentAppointment'
import toastService from '../../../../services/toastService'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'
import CircularProgress from '@material-ui/core/CircularProgress'

const VideoPanelContainer = styled.div`
  background-color: ${() => window.cordova ? 'transparent' : 'black'};
  display: flex;
  flex: 1;
  position: relative;
  min-height: ${({ fullHeight }) => !fullHeight ? 'calc(100vh - 75px - 75px - 48px - 180px - env(safe-area-inset-top))' : '100%'};
`

const SelfVideoPanel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100%;
  height: 300px;
  max-width: 33%;
  max-height: 50%;
  z-index: 10;

  video {
    max-width: 100%;
    max-height: 100%;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg);
    z-index: -1;
    position: absolute;
  }
`

const ParticipantVideoPanel = styled.div`
  display: flex;
  flex: 1;
  z-index: 9;

  video {
    width: 100%;
    height: 100%;
    z-index: -2;
    position: absolute;
  }
`

const VideoControls = styled.div`
  display: flex;
  position: absolute;
  bottom: ${({ isConsultant }) => isConsultant ? 25 : 80}px;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const VideoControlButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  background-color: ${({ active }) => !active
    ? '#1a1a1a'
    : '#f44336'
};
  color: white;
  margin: 0 10px;
  cursor: pointer;
`

const WaitingRoom = styled.div`
  background-color: #F9F6F6;
  color: #340C0C;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 0 20px;
  flex-direction: column;
`

const getPlatformBrowserSupportText = () => {
  return `
    Your browser is unsupported. Please use the following browser dependent on your device:\n
    iOS - Safari
    Android - Chrome
    Mac - Chrome/Firefox
    Windows - Chrome/Firefox
    Linux - Chrome/Firefox
  `
}

const triggerNoPermissionsModal = () => {
  modalService.action({
    title: translations('Video/audio permission denied modal title'),
    text: translations('Video/audio permission denied modal text'),
    actions: [
      {
        success: true,
        text: 'Try again',
        onClick: () => window.location.reload(),
        primary: true
      }
    ]
  })
}

const getSelfVideoPanel = () => {
  return document.getElementById('self-video-panel')
}

const getSelfAudioPanel = () => {
  return document.getElementById('self-audio-panel')
}

const getParticipantVideoPanel = () => {
  return document.getElementById('participant-video-panel')
}

const getParticipantAudioPanel = () => {
  return document.getElementById('participant-audio-panel')
}

const removeSelfVideo = () => {
  const selfVideoPanel = getSelfVideoPanel()
  console.log('DEBUG: could not find self-video-panel')
  if (!selfVideoPanel) return
  while (selfVideoPanel.hasChildNodes()) {
    selfVideoPanel.removeChild(selfVideoPanel.lastChild)
  }
}

export const removeSelfAudio = () => {
  const selfAudioPanel = getSelfAudioPanel()
  if (!selfAudioPanel) return
  while (selfAudioPanel.hasChildNodes()) {
    selfAudioPanel.removeChild(selfAudioPanel.lastChild)
  }
}

const removeParticipantVideo = () => {
  const participantVideoPanel = getParticipantVideoPanel()
  if (!participantVideoPanel) return
  while (participantVideoPanel.hasChildNodes()) {
    participantVideoPanel.removeChild(participantVideoPanel.lastChild)
  }
}

const removeParticipantAudio = () => {
  const participantAudioPanel = getParticipantAudioPanel()
  if (!participantAudioPanel) return
  while (participantAudioPanel.hasChildNodes()) {
    participantAudioPanel.removeChild(participantAudioPanel.lastChild)
  }
}

const showSelfVideo = (localParticipant) => {
  removeSelfVideo()
  const selfVideoPanel = getSelfVideoPanel()
  localParticipant.videoTracks.forEach(publication => {
    publication.track.enable()
    if (selfVideoPanel) {
      selfVideoPanel.appendChild(publication.track.attach())
    }
  })
}

export const showSelfAudio = (localParticipant) => {
  removeSelfAudio()
  const selfAudioPanel = getSelfAudioPanel()
  localParticipant.audioTracks.forEach(publication => {
    publication.track.enable()
    if (selfAudioPanel) {
      selfAudioPanel.appendChild(publication.track.attach())
    }
  })
}

const showParticipantVideo = (participant) => {
  removeParticipantVideo()
  const participantVideoPanel = getParticipantVideoPanel()
  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      if (participantVideoPanel && publication.kind === 'video') {
        participantVideoPanel.appendChild(publication.track.attach())
      }
    }
  })
}

const showParticipantAudio = (participant) => {
  removeParticipantAudio()
  const participantAudioPanel = getParticipantAudioPanel()
  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      if (participantAudioPanel && publication.kind === 'audio') {
        participantAudioPanel.appendChild(publication.track.attach())
      }
    }
  })
}

const VideoPanel = ({ isConsultant }) => {
  const dispatch = useDispatch()
  const [showWaitingRoom, setShowWaitingRoom] = useState(true)
  const [hideVideo, setHideVideo] = useState(false)
  const [muteAudio, setMuteAudio] = useState(false)
  const appointment = useSelector(currentAppointmentSelectors.getCurrentAppointment)
  const isAppointmentComplete = useSelector(currentAppointmentSelectors.getIsAppointmentComplete)
  const isAppointmentEnded = useSelector(currentAppointmentSelectors.getIsAppointmentEnded)
  const appointmentId = _.get(appointment, 'id')
  const clientId = isConsultant ? 'consultant' : 'customer'

  useEffect(() => {
    console.log(`connecting to twilioRoom: ${appointmentId} as ${clientId}`)
    if (appointmentId && !isAppointmentEnded) {
      setShowWaitingRoom(false)
      const cordova = window.cordova
      if (cordova && cordova.plugins && cordova.plugins.iosrtc) {
        // Expose WebRTC and GetUserMedia SHIM as Globals (Optional)
        // Alternatively WebRTC API will be inside cordova.plugins.iosrtc namespace
        cordova.plugins.iosrtc.registerGlobals()
        // Patch MediaStreamTrack with clone
        MediaStreamTrack.prototype.clone = function () {
          return this
        }
        // Enable iosrtc debug (Optional)
        cordova.plugins.iosrtc.debug.enable('*', false)
      }
      MediaStreamTrack.prototype.clone = function () {
        return this
      }

      window.twilioMute = window.twilioMute || false

      if (!window.twilioRoom) {
        dispatch(appointmentsActions.fetchVideoToken({
          clientId,
          appointmentId
        }))
          .then(({ token }) => {
            const twilioVideo = require('twilio-video')

            twilioVideo.connect(token, {
              name: appointmentId,
              audio: true,
              video: {
                width: 640
              },
              sdpSemantics: 'plan-b',
              bundlePolicy: 'max-compat'
            })
              .then(room => {
                console.log('succesfully joined room')
                window.twilioRoom = room
                // add self video & audio to page
                showSelfVideo(room.localParticipant)
                showSelfAudio(room.localParticipant)

                // add current participants to page
                room.participants.forEach(participant => {
                  showParticipantVideo(participant)
                  showParticipantAudio(participant)

                  toastService.action({
                    type: 'success',
                    message: `Successfully connected with ${isConsultant ? 'customer' : 'consultant'}`,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                  })

                  participant.on('trackSubscribed', track => {
                    showParticipantVideo(participant)
                    showParticipantAudio(participant)
                  })
                })

                // add joining participants to page
                room.on('participantConnected', participant => {
                  showParticipantVideo(participant)
                  showParticipantAudio(participant)

                  toastService.action({
                    type: 'success',
                    message: `Successfully connected with ${isConsultant ? 'customer' : 'consultant'}`,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                  })

                  participant.on('trackSubscribed', track => {
                    showParticipantVideo(participant)
                    showParticipantAudio(participant)
                  })
                })

                // remove leaving participants to page
                room.on('participantDisconnected', participant => {
                  removeParticipantVideo()
                  removeParticipantAudio()

                  toastService.action({
                    type: 'info',
                    message: `${isConsultant ? 'Customer' : 'Consultant'} disconnected from virtual consultation`,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                  })
                })
              }, (error) => {
                console.error(`error: ${error.message}`)
                if (twilioVideo.isSupported) {
                  triggerNoPermissionsModal()
                } else {
                  modalService.action({
                    title: 'Browser unsupported',
                    text: getPlatformBrowserSupportText(),
                    actions: [
                      {
                        success: true,
                        text: 'Try again',
                        onClick: () => window.location.reload(),
                        primary: true
                      }
                    ]
                  })
                }
              })
          })
      } else {
        setTimeout(() => {
          // add self video & audio to page
          showSelfVideo(window.twilioRoom.localParticipant)
          showSelfAudio(window.twilioRoom.localParticipant)

          // add current participants to page
          window.twilioRoom.participants.forEach(participant => {
            showParticipantVideo(participant)
            showParticipantAudio(participant)

            toastService.action({
              type: 'success',
              message: `Successfully connected with ${isConsultant ? 'customer' : 'consultant'}`,
              verticalPosition: 'top',
              horizontalPosition: 'right'
            })

            participant.on('trackSubscribed', track => {
              showParticipantVideo(participant)
              showParticipantAudio(participant)
            })
          })
        }, 200)
      }
    }

    return () => {
      if (window.twilioRoom) {
        window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable()
        })

        // moved this to VCAudioContainer for consultant
        if (!isConsultant) {
          window.twilioRoom.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable()
          })

          window.twilioRoom.disconnect()
          window.twilioRoom = null
        }
      }
    }
  }, [appointmentId])


  useEffect(() => {
    if (window.twilioRoom && isAppointmentEnded) {
      window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop()
        publication.unpublish()
      })
      removeSelfVideo()
    }
  }, [isAppointmentEnded])

  const toggleVideo = useCallback(() => {
    if (window.twilioRoom) {
      const twilioVideo = require('twilio-video')
      if (hideVideo) {
        twilioVideo.createLocalVideoTrack().then(localVideoTrack => {
          return window.twilioRoom.localParticipant.publishTrack(localVideoTrack);
        }).then(publication => {
          showSelfVideo(window.twilioRoom.localParticipant)
        })
      } else {
        window.twilioRoom.localParticipant.videoTracks.forEach(publication => {
          publication.track.stop()
          publication.unpublish()
        })
        removeSelfVideo()
      }
      setHideVideo(!hideVideo)
    }
  }, [hideVideo, window.twilioRoom])

  const toggleAudio = useCallback(() => {
    if (window.twilioRoom) {
      if (window.twilioMute) {
        showSelfAudio(window.twilioRoom.localParticipant)
      } else {
        window.twilioRoom.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable()
        })
        removeSelfAudio()
      }
      setMuteAudio(!window.twilioMute)
      window.twilioMute = !window.twilioMute
    }
  }, [window.twilioMute, window.twilioRoom])

  if (isAppointmentEnded) {
    return (
      <WaitingRoom>
        <p style={{ fontWeight: 'bold' }}>{translations('Appointment completed - headline')}</p>
      </WaitingRoom>
    )
  }

  return (
    <VideoPanelContainer fullHeight={!isConsultant || isAppointmentComplete}>
      {
        !isConsultant && showWaitingRoom
          ? (
            <WaitingRoom>
              <CircularProgress size={25} />
              <p style={{ fontWeight: 'bold' }}>{translations('Waiting room - headline')}</p>
              <p>{translations('Waiting room - line 1')}</p>
              <p>{translations('Waiting room - line 2')}</p>
            </WaitingRoom>
          )
          : (
            <>
              <ParticipantVideoPanel id="participant-video-panel" />
              <SelfVideoPanel id="self-video-panel" />
              <VideoControls isConsultant={isConsultant}>
                <VideoControlButton active={hideVideo} onClick={toggleVideo}>
                  {hideVideo ? <VideocamOffIcon /> : <VideocamIcon />}
                </VideoControlButton>
                <VideoControlButton active={muteAudio} onClick={toggleAudio}>
                  {window.twilioMute ? <MicOffIcon /> : <MicIcon />}
                </VideoControlButton>
              </VideoControls>
            </>
          )
      }

    </VideoPanelContainer>
  )
}

export default VideoPanel
