import React from 'react'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import _ from 'lodash'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import { getGroupKeyFromProduct } from '@redant/digital-store-prices-loccitane'
import { push } from 'connected-react-router'
import { withStyles } from '@material-ui/core/styles'

import { translations, storeroomProductNotPickedReasons, isStoreroomProductNotPickedReasonRequired } from '../../../../../../config'
import modalService from '../../../../../../services/modalService'
import currencyFormatter from '../../../../../../formatters/currencyFormatter'
import { actions as orderDetailsActions, selectors as orderDetailsSelectors } from '../../../../../../store/modules/orderDetails'
import { Dropdown, IconCheckbox } from '../../../../../../components/Fields'
import YesNo from '../../../../../../components/Fields/YesNo'
import FormModal from '../../../../../../components/FormModal'
import { getImage } from '../../../../../../components/Images'

import formId from '../../formId'
import OrderProduct from './OrderProduct'

import style from './style'

const cf = currencyFormatter.format

const mapStateToProps = (state) => {
  const isPartialRefundState = orderDetailsSelectors.getIsPartialRefundState(
    state
  )
  const isExchangeState = orderDetailsSelectors.getIsExchangeState(state)
  const orderFormValues = getFormValues(formId)(state)
  return { orderFormValues, isPartialRefundState, isExchangeState }
}

const setPicked = ({ dispatch, fieldNamePrefix, order, product, value, reason }) => {
  order.products.forEach((ungroupedProduct) => {
    if (ungroupedProduct.id === product.id) {
      ungroupedProduct.picked = value
      ungroupedProduct.notPickedReason = reason
    }
  })

  dispatch(change(formId, `${fieldNamePrefix}picked`, value))
  dispatch(change(formId, `${fieldNamePrefix}notPickedReason`, reason || ''))
  dispatch(
    orderDetailsActions.updateOrder({
      id: _.get(order, 'id'),
      status: 'picking_in_progress',
      products: order.products,
      noSpinner: true
    })
  )
}

export default compose(
  connect(mapStateToProps),
  withStyles(style),
  withPropsOnChange(
    [
      'order',
      'product',
      'productInfoLayout',
      'orderFormValues',
      'isPartialRefundState',
      'isExchangeState'
    ],
    (props) => {
      const { config, product, order, orderFormValues, isPartialRefundState, isExchangeState, productInfoLayout, dispatch, classes, useGroupedProducts } = props
      const status = _.get(order, 'status')
      const orderType = _.get(order, 'orderType')
      const fieldNamePrefix = `orderProduct|${getGroupKeyFromProduct(product)}|`
      const pickedKey = 'picked'
      const notPickedReasonKey = 'notPickedReason'
      const notReturnedKey = 'notReturned'
      const getContentForKey = (key) => {
        switch (key) {
          case 'variantName':
            return _.get(product, 'variant.name')
          case 'quantity':
            return product.unsold ? 0 : _.get(product, 'quantity')
          case 'price':
            const priceWasEdited = _.get(product, 'manualDiscount.type') === 'EDIT'
            return cf(useGroupedProducts ? _.get(product, 'groupPriceToDisplay') : _.get(product, 'price')) + (priceWasEdited ? '*' : '')
          case 'discount':
            return cf(useGroupedProducts ? _.get(product, 'groupDiscountToDisplay') : _.get(product, 'discount'))
          case 'total':
            return cf(useGroupedProducts ? _.get(product, 'groupSubTotal') : _.get(product, 'total'))
          case 'picked':
            return (
              <div className={classes.fieldContainer}>
                <YesNo value={product.picked}
                  name={`${fieldNamePrefix}${pickedKey}`}
                  beforeChange={(value) => {
                    // if value set to false, bring up a modal to enter the reason
                    if (
                      value === false &&
                      isStoreroomProductNotPickedReasonRequired
                    ) {
                      // if a reason is required, then we can't just let the field change on click,
                      // we must wait for a reason to be selected before changing the field
                      modalService.open({
                        component: FormModal,
                        formId: 'not-picked-reason',
                        title: translations('Not Picked Reason Modal Title'),
                        text: translations('Not Picked Reason Modal Text'),
                        onSubmit: (values) => {
                          const { reason } = values
                          setPicked({
                            dispatch,
                            fieldNamePrefix,
                            order,
                            product,
                            value,
                            reason
                          })
                          modalService.close()
                        },
                        schema: [{
                          id: 'reason',
                          field: 'Dropdown',
                          props: {
                            name: 'reason',
                            options: (storeroomProductNotPickedReasons || [])
                              .map(reason => ({ label: translations(reason), value: reason })),
                            required: isStoreroomProductNotPickedReasonRequired
                          }
                        }]
                      })
                    } else {
                      setPicked({
                        dispatch,
                        fieldNamePrefix,
                        order,
                        product,
                        value: (value === null) ? undefined : value
                      })
                    }
                  }} />
                <div className={classes.notPickedReason}>
                  {_.get(product, `notPickedReason`, '')}
                </div>
              </div>)
          case 'selectSold':
            return (
              _.get(product, pickedKey)
                ? <div className={classes.fieldContainer}>
                  <IconCheckbox name={`${fieldNamePrefix}${notReturnedKey}`} />
                </div>
                : <div className={classes.fieldContainer}>
                  <img src={getImage('redCancelIcon')} className={classes.notPickedIcon} />
                  <div className={classes.notPickedReason}>
                    {_.get(product, 'notPickedReason')}
                  </div>
                </div>
            )
          case 'notPurchasedReason':
            let productStatus
            if (product.hasBeenRefunded) {
              productStatus = 'Returned'
            } else if (_.get(product, notReturnedKey) === false) {
              productStatus = 'Refund'
            } else if (config.enablePicking) {
              productStatus = _.get(product, pickedKey) ? 'Checkout Item Picked' : 'Checkout Item Unavailable'
            }
            return translations(productStatus)
          case 'quantityToRefund':
            const dropdownOptions = _.range(_.get(product, 'quantity'))
              .map(index => {
                const qty = (index + 1).toString()
                return { label: qty, value: qty }
              })
            const dropdownName = `${_.get(product, 'id')}_quantity`
            return <Dropdown name={dropdownName} options={dropdownOptions} noErrorTextLabel />
          default:
            return `product ${key}`
        }
      }

      const faded = (
        orderType === 'storeroom' &&
        status === 'ready_for_collection' &&
        config.enablePicking &&
        !_.get(product, pickedKey)
      ) || (
          (_.get(product, notReturnedKey) === false) || product.hasBeenRefunded
        ) || (
          product.unsold
        ) || (
          (isPartialRefundState || isExchangeState) && product.refund
        )

      const productInfo = _.transform(
        productInfoLayout,
        (result, [key]) => {
          // Dont show quantity for refund products
          // when item has been faded
          if (key === 'quantityToRefund' && faded) {
            result[key] = null
            return
          }
          result[key] = getContentForKey(key)
        },
        {}
      )

      return { productInfo, faded }
    }
  ),
  withHandlers({
    goToProduct: ({ dispatch, product }) => () => {
      dispatch(push(`/product/${_.get(product, 'productId')}`))
    }
  })
)(OrderProduct)
