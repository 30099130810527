import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { isInvalid, reduxForm, formValueSelector, change } from 'redux-form'
import { compose, withHandlers, withProps, withPropsOnChange, withState } from 'recompose'
import _ from 'lodash'
import { history } from '../../../../store'
import qs from 'qs'

import { communicationOptions } from '../../../../config/general'
import { translations } from '../../../../config'
import { actions as createMessageActions, selectors as createMessageSelectors } from '../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import MessageEditorForm from './MessageEditorForm'
import modalService from '../../../../services/modalService'
import MessagePreviewScreen from '../../MessagePreviewScreen'

export const formId = 'create-message'
const { preferenceOptionKeys, preferenceKeys, channelOptionKeys, channelKeys } = communicationOptions

const messageEditorFormIsInvalidSelector = isInvalid(formId)
const messageEditorFormValueSelector = formValueSelector(formId)

const mapStateToProps = state => {
  const messageTemplateDropdownOptions = createMessageSelectors.getMessageTemplateDropdownOptions(state)
  const messageTemplates = createMessageSelectors.getMessageTemplates(state)
  const isActionPendingState = createMessageSelectors.getMessageTemplatesAreLoading(state)
  const messageDetails = createMessageSelectors.getMessageDetails(state)
  const currentRecipients = createMessageSelectors.getCurrentRecipients(state)
  const storeId = authSelectors.getUserSelectedStoreId(state)
  const regionId = authSelectors.getUserSelectedRegionId(state)
  const isMessageSending = createMessageSelectors.getIsMessageSending(state)
  const messageContent = createMessageSelectors.getMessageContent(state)

  const formCommunicationType = messageEditorFormValueSelector(state, 'communicationType')
  const selectedTemplate = messageEditorFormValueSelector(state, 'template')
  const isFormInvalid = messageEditorFormIsInvalidSelector(state)

  const storeCommunicationType = createMessageSelectors.getMessageCommunicationType(state)

  const recipientMarketingChannels = createMessageSelectors.getSingleRecipientMarketingChannels(state)
  const recipientMarketingPreferences = createMessageSelectors.getSingleRecipientMarketingPreferences(state)
  return {
    messageTemplateDropdownOptions,
    messageTemplates,
    isActionPendingState,
    selectedTemplate,
    messageDetails,
    currentRecipients,
    storeId,
    isMessageSending,
    formCommunicationType,
    isFormInvalid,
    recipientMarketingChannels,
    recipientMarketingPreferences,
    storeCommunicationType,
    messageContent,
    formCommunicationType,
    regionId
  }
}

const mapDispatchToProps = dispatch => {
  const setMessageInRedux = messageFields => {
    dispatch(createMessageActions.updateMessage(messageFields))
  }

  const goToPreview = messageFields => {
    setMessageInRedux(messageFields)

    modalService.open({
      component: MessagePreviewScreen,
      hideBottomBar: true,
      fullScreen: true,
      inModal: true,
      closePreviewScreen: () => modalService.close()
    })
  }

  const sendMessage = params => {
    dispatch(createMessageActions.sendMessage(params))
  }

  const resetSelectedMessageTemplate = () => {
    dispatch(change(formId, "template", ""))
    dispatch(change(formId, 'text', ""))
  }

  return {
    setMessageInRedux,
    goToPreview,
    sendMessage,
    resetSelectedMessageTemplate,
    dispatch
  }
}

// separating this from mapStateToProps to avoid recalculations every time redux store changes
const formatStateProps = withPropsOnChange(
  [
    'formCommunicationType',
    'storeCommunicationType',
    'currentRecipients',
    'isMessageSending',
    'isFormInvalid',
    'createMessage',
    'messageTemplateDropdownOptions',
    'selectedTemplate',
    'recipientMarketingChannels',
    'recipientMarketingPreferences',
    'messageContent'
  ],
  props => {
    const {
      formCommunicationType,
      currentRecipients,
      isMessageSending,
      isFormInvalid,
      messageTemplateDropdownOptions,
      selectedTemplate,
      recipientMarketingPreferences,
      recipientMarketingChannels,
      storeCommunicationType,
      messageContent,
      messageTemplates,
      regionId
    } = props

    const hasRecipients = !!currentRecipients.length
    const hasSingleRecipient = currentRecipients.length === 1

    const optedOutMarketingPreferences = preferenceKeys
      .filter(key => !recipientMarketingPreferences.includes(key))
      .map(key => translations(_.get(preferenceOptionKeys.find(option => option.value === key), 'label')))

    const optedOutMarketingChannels = channelKeys
      .filter(key => !recipientMarketingChannels.includes(key))
      .map(key => _.get(channelOptionKeys.find(option => option.value === key), 'label'))

    const hideSMS = !!(
      hasSingleRecipient &&
      (
        !currentRecipients[0].telephone ||
        !recipientMarketingChannels.includes('smsMarketing')
      )
    )
    const hideEmail = !!(
      hasSingleRecipient &&
      (
        !currentRecipients[0].email ||
        !recipientMarketingChannels.includes('emailMarketing')
      )
    )

    const submitDisabled = isFormInvalid || isMessageSending || (hideEmail && hideSMS)
    const communicationTypeOptions = []
    let communicationType = formCommunicationType || storeCommunicationType || 'sms'

    if (!hideEmail) {
      communicationTypeOptions.push({ value: 'email', label: 'Email' })
    }
    if (!hideSMS) {
      communicationTypeOptions.push({ value: 'sms', label: 'SMS' })
      // communicationTypeOptions.push({ value: 'whatsapp', label: 'Whatsapp' })
    }

    // if the communication option which is chosen is not available
    // enforces only selectable options are actually selected
    if (communicationTypeOptions.length === 1) {
      communicationType = communicationTypeOptions[0].value
    }

    const contactDetail = (
      hasSingleRecipient
        ? getContactDetail({ communicationType, currentRecipients })
        : ''
    )

    const { template: defaultTemplateExternalId } = qs.parse(history.location.search.slice(1)) 
    const messageTemplatesForCurrentRegion = messageTemplates.filter((template) => template.config.regionId === regionId);
    const defaultTemplate = _.find(messageTemplatesForCurrentRegion, (template) => template.externalTemplateId === defaultTemplateExternalId)
    const selectedTemplateId = _.get(selectedTemplate, 'id')
    const defaultTemplateId = _.get(defaultTemplate, 'id')
   
    const initialValues = {
      content: messageContent,
      template: selectedTemplateId || defaultTemplateId,
      communicationType
    }

    return {
      optedOutMarketingPreferences,
      optedOutMarketingChannels,
      contactDetail,
      hasSingleRecipient,
      hasRecipients,
      submitDisabled,
      initialValues,
      communicationTypeOptions,
      communicationType,
      hideSMS,
      hideEmail
    }
  }
)

function getContactDetail ({ communicationType, currentRecipients }) {
  switch (communicationType) {
    case 'sms':
      return `Phone: ${currentRecipients[0].telephone}`
    case 'whatsapp':
      return `Whatsapp: ${currentRecipients[0].telephone}`
    case 'email':
      return `Email: ${currentRecipients[0].email}`
    default:
      return ''
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  formatStateProps,
  withPropsOnChange(['selectedTemplate'], props => {
    const { messageTemplates, selectedTemplate, initialValues, dispatch, formCommunicationType } = props
    const tpl = _.chain(messageTemplates)
      .find(tpl => tpl.id === selectedTemplate)
      .defaultTo({ config: {} })
      .value()

    if (tpl.config.prefillSubject) {
      dispatch(change(formId, 'subject', tpl.config.prefillSubject))
    }
    
    if (formCommunicationType == 'email' && tpl.config.prefillBody) {
      dispatch(change(formId, 'text', tpl.config.prefillBody))
    }
    if (formCommunicationType === 'sms' && tpl.config.smsPrefillBody) {
      dispatch(change(formId, 'text', tpl.config.smsPrefillBody))
    }

    return {}
  }
  ),
  withState('isFormLoading', 'setIsformLoading', false),
  withPropsOnChange(['isActionPendingState', 'isFormLoading'], ({ isActionPendingState, isFormLoading }) => {
    return { isLoading: isActionPendingState || isFormLoading }
  }),
  FullScreenLoader,
  reduxForm({ form: formId, enableReinitialize: false }),
  withProps({
    formId
  }),
  withHandlers({
    onSubmit: ({ currentRecipients, setMessageInRedux, sendMessage, storeId }) => message => {
      setMessageInRedux(message)
      sendMessage({ message, recipients: currentRecipients, storeId })
    },
    onClickPreview: ({ handleSubmit, goToPreview }) => () => {
      handleSubmit(messageFields => {
        goToPreview(messageFields)
      })()
    }
  })
)(MessageEditorForm)
