import * as validators from '../../../validators'
import { countryPhoneCodes } from '../../../config'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')

export default ({ regions = [], catalogues = [] } = {}) => {
  return {
    schema: [{
      id: 'name',
      field: 'Input',
      props: {
        label: 'Name',
        name: 'name',
        required: true
      }
    }, {
      id: 'externalStoreId',
      field: 'Input',
      props: {
        label: 'External ID',
        name: 'externalStoreId',
        required: true
      }
    }, {
      id: 'regionId',
      field: 'Dropdown',
      props: {
        label: 'Region',
        name: 'regionId',
        options: [regions],
        validate: [smallTextLengthValidator],
        required: true
      }
    }, {
      id: 'catalogue',
      field: 'AutoComplete',
      props: {
        label: 'Catalogue',
        name: 'catalogue',
        required: true,
        autocomplete: true,
        options: catalogues
      }
    }, {
      id: 'contactEmail',
      field: 'Email',
      props: {
        label: 'Contact Email',
        name: 'contactEmail',
        validate: [validators.email, smallTextLengthValidator],
        required: true,
        showEmailButton: false
      }
    }, {
      id: 'contactTelephone',
      field: 'PhoneNumber',
      props: {
        label: 'Outbound SMS number',
        name: 'contactTelephone',
        options: countryPhoneCodes,
        validate: [telephoneLengthValidator, validators.phoneNumber],
        showSmsButton: false
      }
    }],
    layout: [
      ['name']
    ]
  }
}
