export const TOGGLE_SIDE_MENU = 'digitialStore/app/TOGGLE_SIDE_MENU'
export const TOGGLE_BASKET = 'digitalStore/app/TOGGLE_BASKET'
export const OPEN_WEBVIEW = 'digitialStore/app/OPEN_WEBVIEW'
export const APP_READY = 'digitalStore/app/APP_READY'
export const TOGGLE_CUSTOMER_MODE = 'digitalStore/app/TOGGLE_CUSTOMER_MODE'
export const ON_API_FAIL = 'digitalStore/app/ON_API_FAIL'
export const ON_API_DISMISS = 'digitalStore/app/ON_API_DISMISS'
export const CHANGE_CLIENT_BOOK_TAB = 'digitalStore/app/CHANGE_CLIENT_BOOK_TAB'
export const CLIENT_BOOK_CONTACT_DETAILS = 'contact-details'
export const CLIENT_BOOK_ACTIVITIES_LIST = 'activities-list'
export const CHANGE_SNAPSHOT_METRIC_TAB = 'digitalStore/app/CHANGE_SNAPSHOT_METRIC_TAB'
export const SNAPSHOT_THIS_MONTH = 'SNAPSHOT_THIS_MONTH'
export const SNAPSHOT_THIS_YEAR = 'SNAPSHOT_THIS_YEAR'
export const SNAPSHOT_LIFETIME = 'SNAPSHOT_LIFETIME'
export const SNAPSHOT_WISHLIST = 'SNAPSHOT_WISHLIST'
export const SNAPSHOT_PURCHASES = 'SNAPSHOT_PURCHASES'
export const SNAPSHOT_RECOMMENDATIONS = 'SNAPSHOT_RECOMMENDATIONS'
export const CHANGE_SNAPSHOT_CAROUSEL_TAB = 'digitalStore/app/CHANGE_SNAPSHOT_CAROUSEL_TAB'
export const CHANGE_POLLS_TAB = 'digitalStore/app/CHANGE_POLLS_TAB'
export const POLLS_QUESTIONS = 'digitalStore/app/POLLS_QUESTIONS'
export const POLLS_RESULTS = 'digitalStore/app/POLLS_RESULTS'
export const CHANGE_STOREROOM_KANBAN_SELECTED_COLUMN = 'digitalStore/app/CHANGE_STOREROOM_KANBAN_SELECTED_COLUMN'
export const CHANGE_INSPIRATIONS_TAB = 'digitalStore/app/CHANGE_INSPIRATIONS_TAB'
export const BRAND_INSPIRATIONS = 'BRAND_INSPIRATIONS'
export const MY_INSPIRATIONS = 'MY_INSPIRATIONS'
export const TOGGLE_CUSTOMER_REVIEW_BASKET = 'digitalStore/app/TOGGLE_CUSTOMER_REVIEW_BASKET'
export const LOCK_SCREEN = 'digitalStore/app/LOCK_SCREEN'
export const CHANGE_LANGUAGE = 'digitalStore/app/CHANGE_LANGUAGE'

