import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class StoresActions {
  fetchAllStores = (params) => ({
    type: constants.FETCH_ALL_STORES,
    promise: () => digitalStoreSdk.stores.fetchStores({ ...params, limit: 500 })
  })
}

export default new StoresActions()
