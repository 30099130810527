import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style'
import _ from 'lodash'
import pluralize from 'pluralize'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import { translations, productsFilters } from '../../../../../config'
import H2 from '../../../../../components/H2'
import P from '../../../../../components/P'
import Dropdown from '../../../../../components/Fields/Dropdown'
import EditMode from '../../../../../components/EditMode'

class TitleBar extends Component {
  state = {
    sort: this.props.sort
  }

  searchProducts = _.debounce(({ sort }) => {
    const { searchProducts } = this.props
    searchProducts({ sort })
  }, 300)

  updateFilter = (e) => {
    const sort = e.target.value
    this.searchProducts({ sort })
    this.setState({ sort })
  }

  renderTitle = () => {
    const { total, query, category, isLoading } = this.props

    if (total > 0) {
      if (query) {
        if (category) {
          return _.template(translations('Search results for query in category'))({ query, category: category.name })
        } else {
          return _.template(translations('Search results for query'))({ query })
        }
      } else {
        if (category) {
          return _.template(translations('All products in category'))({ category: category.name })
        } else {
          return translations('All products')
        }
      }
    } else if (!isLoading) {
      if (query) {
        if (category) {
          return _.template(translations('No products found for query in category'))({ query, category: category.name })
        } else {
          return _.template(translations('No products found for query'))({ query })
        }
      } else {
        return translations('No products found')
      }
    }
  }

  render () {
    const { total, classes, editMode, orderNumber, stopEditingOrder } = this.props
    const translatedProductFilters = productsFilters.map((item) => ({ ...item, label: translations(item.label) }))
    return (
      <div>
        {editMode ? <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} /> : null}
        <Grid className={classes.container} container spacing={2}>
          <Grid className={classes.hideForSmall} item xs={12} md={4} />
          <Grid className={classes.titleContent} item xs={12} md={4}>
            <H2 value={this.renderTitle()} className={classes.title} />
          </Grid>
          <Grid className={classes.sortContent} item xs={12} md={4}>
            <P className={classes.productCount} value={total > 0 && `${total} ${pluralize(translations('Product'), total)}`} />
            <div className={classes.filterContainer}>
              <FormControl>
                <Dropdown
                  label={!this.state.sort ? translations('Sort') : undefined}
                  value={this.state.sort === null ? '' : this.state.sort}
                  onChange={this.updateFilter}
                  options={translatedProductFilters}
                  nullOptionLabel={translations('Default')}
                  noErrorTextLabel
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

TitleBar.propTypes = {
  total: PropTypes.number,
  query: PropTypes.string,
  category: PropTypes.object,
  isLoading: PropTypes.bool
}

export default withStyles(style)(TitleBar)
