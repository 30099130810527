import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

import Form, { FormSubmit } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { Input } from '../../../../../components/Fields'
import ButtonsContainer from '../../../../../components/ButtonsContainer'
import P from '../../../../../components/P'
import { translations, salesforceOCAPI } from '../../../../../config'

import style from './style'

export const formId = 'order-notes'

const NotesForm = Form(formId)

const OrderNotes = (props) => {
  const { classes, initialValues, onSubmit, editing, toggleEdit } = props

  return <NotesForm
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <FormControl className={classes.formControl}>
          <P
            value={translations('Notes')}
            className={classes.label}
          />
          {
            editing
            ? <Input
              name='notes'
              id='notes'
              className={classes.input}
              multiline
              rowsMax={5}
              noErrorTextLabel
              noLabel
            />
            : <P
              value={_.get(initialValues, 'notes')}
              className={classes.noteNotEditing}
            />
          }
        </FormControl>
      </div>
      {!salesforceOCAPI.basketEnabled ? (
        <div className={classes.buttonsContainer}>
          <ButtonsContainer>
            {
              editing
                ? <Fragment>
                  <Button
                    buttonType='white'
                    onClick={toggleEdit}
                    flexGrow
                  >
                    Cancel
              </Button>
                  <FormSubmit
                    noContainer
                    flexGrow
                  >
                    Save Note
              </FormSubmit>
                </Fragment>
                : <Button
                  buttonType='white'
                  onClick={toggleEdit}
                  flexGrow
                >
                  {translations('Edit Note')}
            </Button>
            }
          </ButtonsContainer>
        </div>
      ) : (null)}
    </div>
  </NotesForm>
}

export default withStyles(style)(OrderNotes)
