import { Services } from '@redant/retailos-ui'
import { environment } from '../config/environments'
import store from '../store'
import { LoccitaneAnalyticsFunctions } from './RetailAnalytics/implementation.functions.loccitane'

export const httpClientService = new Services.AxiosHttpClientService()
export const retailAnalyticsFunctions = new LoccitaneAnalyticsFunctions()

const sdkGetConfig = () => {
  const auth = _.get(store.getState(), 'auth')
  const token = _.get(auth, 'user.token')
  const refreshToken = _.get(auth, 'user.refreshToken')
  const userId = _.get(auth, 'user.id')

  return { token, refreshToken, userId }
}

const sdkOnRefresh = (user) => {
  const { token, refreshToken, id } = user
  // Use window.sdk instead of importing digitalStoreSdk which imports config and has some dirty circular dependencies
  window.sdk.setConfig({ token, refreshToken, userId: id })

  // again to avoid circular dependencies with importing config in the auth module
  store.dispatch({ user, type: 'digitialStore/auth/UPDATE_AUTH_USER' })
  // uiConfigStore.refresh()
}

let hasInitialized = false

export async function initializeDependencies (overrides = {}) {
  if (hasInitialized) {
    console.debug('RetailOS platform dependencies have already initialized.')
    return
  }

  /**
   * Add the ability to override dependencies here
   */
  // overrides.productCatalogueFunctions && (productCatalogueFunctions = overrides.productCatalogueFunctions)

  await httpClientService.initialize({
    retailosApiBaseUrl: environment.API_URL,
    getConfig: sdkGetConfig,
    onRefresh: sdkOnRefresh
  })

  hasInitialized = true
}
