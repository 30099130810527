import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import CustomerSearch from '../CustomerSearch'
import FollowingListItem from '../FollowingListItem'
import Button from '../../../components/Button'
import AlphabetList from '../../../components/AlphabetList'
import BottomBar from '../../../components/BottomBar'
import Container from '../../../components/Container'
import H2 from '../../../components/H2'
import ButtonsContainer from '../../../components/ButtonsContainer'
import EditMode from '../../../components/EditMode'

import { translations, checkoutAllowUnassignedOrder } from '../../../config'
import style from './style'

const listItemProps = { noMessageButton: true }

const SelectCustomer = ({
  classes,
  onCustomerClick,
  onAnonymousCustomerClick,
  onUnassignedOrderClick,
  following,
  registerCustomer,
  checkoutType,
  editMode,
  orderNumber,
  stopEditingOrder,
  onBackClick
}) => (
  <CustomerSearch
    key='checkout-customer-search'
    onCustomerClick={onCustomerClick}
    onBackClick={onBackClick}
  >
    {editMode && <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} />}
    <Container followingList>
      <H2
        className={classes.message}
        value={translations('Choose Customer')}
      />
      <AlphabetList
        items={following}
        onClickItem={item => () => onCustomerClick(item)}
        ItemComponent={FollowingListItem}
        sortKey='firstName'
        itemProps={listItemProps}
      />
    </Container>
    <BottomBar>
      <ButtonsContainer align='center'>
        {/* <Button
          buttonType='primary'
          big
          onClick={registerCustomer}
        >
          {translations('Register Customer')}
        </Button> */}
        {checkoutType !== 'referral' &&
          <Button
            buttonType='primary'
            big
            onClick={onAnonymousCustomerClick}
          >
            {translations('ANONYMOUS')}
          </Button>
        }
        {checkoutAllowUnassignedOrder &&
          <Button
            buttonType='primary'
            big
            onClick={onUnassignedOrderClick}
          >
            {translations('Unassigned Order')}
          </Button>
        }
      </ButtonsContainer>
    </BottomBar>
  </CustomerSearch>
)

export default withStyles(style)(SelectCustomer)
