import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import { mix } from 'polished'

import { actions as appActions, selectors as appSelectors } from '../../../../../store/modules/app'
import { selectors as customerDetailsSelectors } from '../../../../../store/modules/customerDetails'
import { selectors as categoriesSelectors } from '../../../../../store/modules/categories'
import { colors } from '../../../../../config/theme'

import { customerSnapshot as customerSnapshotConfig } from '../../../../../config'

import CustomerSnapshotSpendMetrics from './CustomerSnapshotSpendMetrics' 

const getChartColor = (i, total) => {
  total = total <= 1 ? 2 : total
  const transparency = (i / (total - 1)) * 0.8
  const color = mix(transparency, '#fff', colors.primary)
  return color
}

const mapStateToProps = state => ({
  tabValue: appSelectors.getSnapshotMetricTab(state),
  customerReporting: customerDetailsSelectors.getCustomerReporting(state),
  categoriesHashmap: categoriesSelectors.getCategoryHashmap(state)
})

const filterOutParentCategories = ({ category, categoriesHashmap }) => {
  const categoryId = category[0]
  const foundChildCatWithMatchingParent = _.pickBy(categoriesHashmap, c => {
    return c.parentId === categoryId
  })
  // if more than one - false
  // if 0 - true
  return !_.size(foundChildCatWithMatchingParent)
}

const filterOutChildCategories = ({ category, categoriesHashmap }) => {
  const id = category[0]
  const foundCategory = categoriesHashmap[id]
  return !_.get(foundCategory, 'parentId')
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    handleTabChange: ({ dispatch }) => (event, value) => {
      // one is a native select so have to use event
      dispatch(appActions.changeSnapshotMetricTab({ snapshotMetricTab: value || event.target.value }))
    },
    getData: ({ customerReporting, categoriesHashmap }) => period => {
      return _.chain(customerReporting)
        .get(`${period}.categorySpend`, {})
        .toPairs()
        .sortBy(obj => _.chain(obj)
          .nth(1)
          .get('total.value', 0)
          .value()
        )
        // want the highest not the lowest
        .reverse()
        .filter(category => {
          if (customerSnapshotConfig.categorySpendFormat === 'child') {
            return filterOutParentCategories({ category, categoriesHashmap })
          } else {
            // default to parent
            return filterOutChildCategories({ category, categoriesHashmap })
          }

        })
        .take(5)
        .map((obj, i, total) => {
          const name = _.get(obj, `[1].category.name`)
          return {
            name,
            value: _.chain(obj)
              .nth(1)
              .get('total.value', 0)
              .value(),
            color: getChartColor(i, total.length)
          }
        })
        .value()
    }
  })
)(CustomerSnapshotSpendMetrics)
