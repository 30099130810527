import { colors } from '../../../config/theme'

const padding = 14

const styles = {
  image: {
    height: 70,
    width: 70,
    marginTop: 5
  },
  faded: {
    opacity: 0.3
  },
  container: {
    padding,
    display: 'flex',
    flexDirection: 'column'
  },
  gridContainer: {
    minHeight: '100%',
    width: '100%',
    border: `1px solid ${colors.lightGrey}`,
    padding,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  topContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  bottomContent: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leftCol: {
  },
  previewBadge: {
    position: 'relative',
    width: '70%',
    height: 'auto',
    paddingTop: '70%',
    margin: '10px 15% 0'
  },
  previewBadgeText: {
    fontSize: '0.6rem',
    padding: 0
  },
  rightCol: {
    flex: 1,
    margin: '0 7px'
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  variant: {
    textAlign: 'left',
    marginBottom: 5
  },
  name: {
    textAlign: 'left'
  },
  priceLine: {
    marginBottom: 5
  },
  gap: {
    height: 19
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column'
  },
  bottomInner: {
    alignItems: 'flex-end'
  },
  trashIconImg: {
    width: 12
  },
  trashIconButton: {
    width: 30,
    height: 30,
    display: 'flex',
    padding: 0
  },
  loveIconButton: {
    width: 30,
    height: 30,
    display: 'flex',
    padding: 0
  },
  text: {
    margin: '0px',
    '&:last-child': {
      marginBottom: 0
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  underline: {
    textDecoration: 'underline'
  },
  strikethrough: {
    textDecoration: 'line-through'
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  quantityContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: 15,
    paddingBottom: 5
  },
  purchaseButton: {
    flex: 0.5
  },
  innerDropdown: {
    flex: 0.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  qtyStyle: {
    textAlign: 'right',
    paddingTop: 5,
    paddingRight: 22
  },
  qtyDropdown: {
    minWidth: 80
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div': {
      margin: 0,
      marginRight: 7
    },
    '& > div:last-child': {
      marginRight: 0
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 -10px'
  },
  totalPrice: {
    fontWeight: 600,
    marginLeft: 3
  },
  unitPriceEdit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  priceContainerInner: {
    display: 'flex',
    flexDirection: 'column'
  },
  priceContainerInnerTwo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  priceLabel: {
    marginRight: '3px'
  }
}

export default theme => styles