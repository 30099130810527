import PouchDB from 'pouchdb'
import _ from 'lodash'

import { environment } from '../../config'
import { convertToProductGroups } from '../../store/modules/products/parseProductGroups'
import { getAppointmentsState } from '../../store/modules/appointments/selectors'
import retryPromise from '../../helpers/retryPromise'

PouchDB.plugin(require('pouchdb-find').default)

class CouchbaseService {
  constructor () {
    PouchDB.on('created', function (dbName) {
      console.log(`CREATED ${dbName}`)
    })
    PouchDB.on('destroyed', function (dbName) {
      console.log(`DESTROYED ${dbName}`)
    })

    this.connections = {
      categories: this.connect({ tableName: 'categories' }),
      products: this.connect({ tableName: 'products' }),
      appointments: this.connect({ tableName: 'appointments', twoWaySync: true })
    }

    // we are using mango query language
    // At a basic level, there are two steps to running a query: createIndex() (to define which fields to index) and find() (to query the index).
    this._createIndexes({
      db: this.connections.categories,
      fields: [
        '_id',
        'name',
        'parentId',
        'regionIds',
        'deletedAt'
      ]
    })
    this._createIndexes({
      db: this.connections.products,
      fields: [
        '_id',
        'name',
        'externalProductId',
        'catalogue',
        'brand',
        'details.description',
        'details.summary',
        'productGroup',
        'regionId',
        'categoryId',
        'deletedAt'
      ]
    })
  }

  _createIndexes ({ db, fields }) {
    return db.createIndex({
      index: {
        fields
      }
    })
  }

  /**
   * @description get all available connections to couchbase
   * @returns {object} database connections
   */
  getConnections () {
    return this.connections
  }

  /**
   * @description use this function to make sure your always get an active version
   * of the virtualconsultations pouchdb table. this is used to bypass this error:
   * `IDBDatabase.transaction: Can't start a transaction on a closed database, retrying failed promise...`
   * @returns
   * @memberof CouchbaseService
   */
  async getAppointmentsTable () {
    return this.connections.appointments
  }

  /**
   * @description use pouchdb to create & sync to cloudant couchbase db
   * @param {*} object
   * @param {string} object.databaseUrl
   * @param {string} object.tableName
   * @returns {*} database connection
   */
  connect ({ databaseUrl, tableName, twoWaySync = false }) {
    if (!databaseUrl) {
      databaseUrl = environment.CLOUDANT_DATABASE_URL
    }
    if (!databaseUrl || !tableName) {
      throw new Error('missing required databaseUrl/tableName args for couchbase connection')
    }

    const couchbaseDB = this._connectToRemote({ databaseUrl, tableName })

    if (twoWaySync) {
      return couchbaseDB
    }

    const pouchDB = this._connectToLocal({ tableName })

    PouchDB.replicate(couchbaseDB, pouchDB, {
      live: true, // automatically watches for changes & syncs
      retry: true // if true will attempt to retry replications in the case of failure (due to being offline)
      // doc_ids: [] // use this to only sync the docs with these ids
    })

    return pouchDB
  }

  /**
   * @description use pouchdb to connect to cloudant couchbase db
   * @param {*} object
   * @param {string} object.databaseUrl
   * @returns {*} database connection
   */
  _connectToLocal ({ tableName }) {
    return new PouchDB(`${tableName}`)
  }

  /**
   * @description use pouchdb to connect to remote cloudant couchbase db
   * @param {*} object
   * @param {string} object.databaseUrl
   * @param {string} object.tableName
   * @returns {*} database connection
   */
  _connectToRemote ({ databaseUrl, tableName }) {
    return new PouchDB(`${databaseUrl}/${tableName}`)
  }

  _reapplyDocIds (docs) {
    return _.map(docs, (doc) => ({
      id: doc._id,
      ...doc
    }))
  }

  /**
   * @description offline mode replacement for digitalStoreSdk.searchProducts
   * @param {*} { region, query, sort, category, page, size }
   * @memberof CouchbaseService
   */
  searchProducts ({ region: regionId, query, sort, category: categoryId, page, size }) {
    // REMEMBER a field must be indexed inorder to query for it
    const regexQuery = new RegExp(`.*${query}.*`, 'i')
    return this.connections.products.find({
      selector: {
        $and: [
          ...query ? [{
            $or: [
              { _id: { $regex: regexQuery } },
              { name: { $regex: regexQuery } },
              { externalProductId: { $regex: regexQuery } },
              { catalogue: { $regex: regexQuery } },
              { brand: { $regex: regexQuery } },
              { 'details.description': { $regex: regexQuery } },
              { 'details.summary': { $regex: regexQuery } },
              { productGroup: { $regex: regexQuery } }
            ]
          }] : [],
          ...regionId ? [{ regionId }] : [],
          ...categoryId ? [{ categoryId }] : [],
          { deletedAt: { $type: 'null' } }
        ]
      },
      ...(page && size) && { skip: (page - 1) * size }
    })
      .then(({ docs: products, warning }) => {
        const { groups } = convertToProductGroups(this._reapplyDocIds(products))
        let groupedProducts = groups

        if (size) {
          groupedProducts = _.slice(groups, 0, size)
        }

        // do not sort in the pouchdb query becuase the conversion to product grouping scrambles the order
        // use this instead
        if (sort) {
          groupedProducts = _.sortBy(groupedProducts, (product) => {
            if (sort === 'NAME_ASC' || sort === 'NAME_DESC') {
              return _.get(product, 'groupName')
            }
            if (sort === 'PRICE_ASC' || sort === 'PRICE_DESC') {
              return parseFloat(_.get(product, 'children[0].price.value', '0'))
            }
          })
          if (sort === 'NAME_DESC' || sort === 'PRICE_DESC') {
            groupedProducts = _.reverse(groupedProducts)
          }
        }

        return {
          query,
          sort,
          category: categoryId,
          total: _.size(products),
          results: {
            groups: groupedProducts
          }
        }
      })
  }

  /**
   * @description offline mode replacement for digitalStoreSdk.fetchCategories
   * @param {*} { region }
   * @memberof CouchbaseService
   */
  fetchCategories ({ region: regionId }) {
    // ignoring regionId for the timebeing
    return this.connections.categories.find({
      selector: {
        $and: [
          ...regionId ? [
            { regionIds: { $elemMatch: regionId } }
          ] : [],
          { deletedAt: { $type: 'null' } }
        ]
      }
    })
      .then(({ docs: categories, warning }) => {
        return this._reapplyDocIds(categories)
      })
  }

  /**
   * @description offline mode replacement for digitalStoreSdk.fetchProduct
   * @param {*} { id, storeId }
   * @memberof CouchbaseService
   */
  fetchProduct ({ id, storeId }) {
    return this.connections.products.find({
      selector: {
        $and: [
          { _id: id },
          { deletedAt: { $type: 'null' } }
        ]
      }
    })
      .then(({ docs: products, warning }) => {
        let product = _.first(this._reapplyDocIds(products))
        if (!product) {
          throw new Error(`Could not find product with id: ${id}`)
        }

        const variants = _.get(product, 'variants', [])
        product = {
          ...product,
          variants: _.map(variants, (variant) => {
            const variantStoreStocks = _.get(variant, 'variantStoreStocks', [])
            let variantStoreStock = _.find(variantStoreStocks, (storeStock) => {
              return _.get(storeStock, 'storeId') === storeId
            })
            if (_.isEmpty(variantStoreStock)) {
              variantStoreStock = { stock: 0, details: {}, storeId: null }
            }

            return {
              ..._.omit(variant, ['variantStoreStocks']),
              variantStoreStock: variantStoreStock
            }
          })
        }

        return product
      })
  }

  /**
   * @description offline mode replacement for digitalStoreSdk.fetchProduct
   * i have not battle tested this function as there are no product groups in retailos databases
   * @param {*} { id, storeId }
   * @memberof CouchbaseService
   */
  fetchProductGroupProducts ({ productGroup, storeId }) {
    return this.connections.products.find({
      selector: {
        $and: [
          { productGroup: productGroup },
          { deletedAt: { $type: 'null' } }
        ]
      }
    })
      .then(({ docs: products, warning }) => {
        const { groups } = convertToProductGroups(this._reapplyDocIds(products))
        let groupedProducts = _.get(groups, '[0].children')
        let variantsCount = 0

        groupedProducts = _.map(groupedProducts, (product) => {
          const variants = _.get(product, 'variants', [])
          variantsCount += _.size(variants)
          return {
            ...product,
            variants: _.map(variants, (variant) => {
              const variantStoreStocks = _.get(variant, 'variantStoreStocks', [])
              let variantStoreStock = _.find(variantStoreStocks, (storeStock) => {
                return _.get(storeStock, 'storeId') === storeId
              })
              if (_.isEmpty(variantStoreStock)) {
                variantStoreStock = { stock: 0, details: {}, storeId: null }
              }

              return {
                ..._.omit(variant, ['variantStoreStocks']),
                variantStoreStock: variantStoreStock
              }
            })
          }
        })

        return {
          total: variantsCount,
          results: groupedProducts
        }
      })
  }

  async updateAppointmentSharedViewState ({ appointmentId, ...consultationProperties }) {
    const appointments = await this.getAppointmentsTable()
    return appointments.find({
      selector: {
        _id: appointmentId
      }
    })
      .then(({ docs: appointmentDocs, warning }) => {
        const appointmentDoc = _.first(appointmentDocs)
        if (warning) {
          console.warn(warning)
        }
        if (!appointmentDoc) {
          console.log({ appointmentDocs })
          throw new Error(`could not find appointment in couchbase with id: ${appointmentId}`)
        }
        return appointments.put({
          ...appointmentDoc,
          sharedViewState: {
            // ..._.get(appointmentDoc, 'sharedViewState', {}),
            ...consultationProperties
          }
        })
          .catch((error) => {
            console.error(error.message)
          })
      })
  }
}

export default new CouchbaseService()
