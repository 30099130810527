import _ from 'lodash'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getAvailableLanguages } from '../../config'

class LanguageSelect extends Component {
  handleChange = event => {
    const lang = event.target.value

    this.props.onLanguageChange(lang)
  }

  render = () => {
    const { classes, selectedLanguage } = this.props
    // This should come from the store
    const languages = getAvailableLanguages()

    return languages.length > 1 ? (
      <div className={classes.container}>
        <Select native={false} onChange={this.handleChange} value={selectedLanguage}>
          {
            languages.map((lang) => {
              return (
                <MenuItem key={`lang-select-${lang}`} value={lang}>{_.toUpper(lang)}</MenuItem>
              )
            })
          }
        </Select>
      </div>
    ) : null
  }
}

export default withStyles(style)(LanguageSelect)
