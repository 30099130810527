import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import ChartWrapper from '../ChartWrapper'
import List from '../../List'
import P from '../../P'
import Button from '../../Button'

import style from './style'
import { translations } from '../../../config'

const ListChart = props => {
  let {
    ItemComponent,
    listItem,
    chartTitle,
    classes,
    data = [],
    isLeaderBoard,
    limit = 5,
    onClickItem,
    seeAllLink,
    ...chartOptionsRest
  } = props

  ItemComponent = ItemComponent || _.get(listItem, 'ChartItemComponent')
  onClickItem = onClickItem || _.get(listItem, 'onClickItem')

  const showData = data && data.length
  const wrapperClass = (
    showData
    ? (seeAllLink ? classes.chartWrapperWithSeeAll : classes.chartWrapper)
    : ''
  )

  return (
    <ChartWrapper title={chartTitle} className={wrapperClass}>
      {
        showData
        ? <Fragment>
          {
            isLeaderBoard ? (
              <div>
                {/* <div className={classes.leaderBoardTile}>
                  <div className={classes.subtitle}>
                    <P value={'Your current position'} className={classes.smallItalic} />
                  </div>
                  <LeaderBoardListItem isLeaderBoard={isLeaderBoard} value={'Logged in User'} />
                </div>
                <div className={classes.leaderBoardListContainer}>
                  <div className={classes.subtitle}>
                    <P value={'Leaderboard'} className={classes.smallItalic} />
                  </div>
                  <List
                    items={data.slice(0, limit)}
                    ItemComponent={ItemComponent}
                    onClickItem={onClickItem}
                    noMargin
                  />
                </div> */}
              </div>
            ) : (
              <List
                items={data.slice(0, limit)}
                ItemComponent={ItemComponent}
                onClickItem={onClickItem}
                itemProps={chartOptionsRest}
                noMargin
              />
            )
          }
          {
            seeAllLink
            ? (
              <Link to={seeAllLink}>
                <Button className={classes.seeAllButton}>
                  <P type='textButton' value={translations('See All')} className={classes.seeAllText} />
                </Button>
              </Link>
            )
            : null
          }
        </Fragment>
        : null
      }
    </ChartWrapper>
  )
}

export default withStyles(style)(ListChart)
