import _ from 'lodash'
import { translations } from '../config'

export const getFullName = (...args) => {
  let obj
  if (typeof args[0] === 'object') {
    obj = args[0]
  } else {
    let [ firstName, lastName ] = args
    obj = { firstName, lastName }
  }
  const { firstName = '', lastName = '' } = obj
  if (firstName || lastName) {
    return _.startCase(`${firstName} ${lastName}`)
  } else {
    return null
  }
  
}

export const getCustomerFullName = customer => {
  const { firstName, lastName, anonymised } = customer || {}
  if (anonymised) {
    return translations('Anonymised Customer')
  }
  if (!firstName && !lastName) {
    return translations('ANONYMOUS')
  }
  return getFullName({ firstName, lastName })
}

export const getProductFullName = product => {
  const { name, varient } = product
  return name + (varient ? ' ' + varient.name : '')
}

export default getFullName
