import Joi from '@hapi/joi'

const baseSchema = Joi.object().keys({
  enabled: Joi.boolean().required(),
  route: Joi.string().optional(),
  dashboard: Joi.boolean().required(),
  menu: Joi.boolean().required(),
  title: Joi.string().required(),
  image: Joi.string().required(),
  icon: Joi.string().required().allow(''),
  badgeType: Joi.string().valid('basket', 'notifications').optional(),
  config: Joi.object().optional(),
  allowOffline: Joi.boolean().optional()
})

const departmentsSchema = Joi.object().keys({
  enabled: Joi.boolean().required()
})

const consultationsSchema = baseSchema.keys({
  config: Joi.object().keys({
    serviceRequired: Joi.boolean().required(),
    beforeAfterPhotos: Joi.boolean().required(),
    steps: Joi.array().items(
      Joi.object().keys({
        type: Joi.string().required().valid(
          'inspirationSelect',
          'customerSelect',
          'customerNotes',
          'beforePhotoSelect',
          'afterPhotoSelect',
          'productSelect',
          'review'
        ),
        selectedLabel: Joi.string().optional(),
        defaultLabel: Joi.string().optional(),
        disabledMessage: Joi.string().optional()
      })
    )
  })
})

const customerSchema = baseSchema.keys({
  config: Joi.object().keys({
    detailsFields: Joi.array().items(
      Joi.object().keys({
        id: Joi.string().required(),
        field: Joi.string().required(),
        label: Joi.string(),
        type: Joi.string(),
        validate: Joi.object().keys({
          maxLength: Joi.number()
        }),
        options: Joi.array(),
        multiple: Joi.boolean(),
        editable: Joi.boolean()
      })
    ),
    viewCustomerScreenLayout: Joi.array().items(
      Joi.object().keys({
        type: Joi.string().valid(
          'PERSONAL_DETAILS',
          'ADDRESS_DETAILS',
          'CUSTOMER_PREFERENCES',
          'NOTES',
          'LOYALTY',
          'APPOINTMENTS_LISTING',
          'COMMS_OPTIONS',
          'APP_COMMS',
          'MESSAGES',
          'RECOMMENDED_PRODUCTS',
          'WISHLIST',
          'WAITLIST',
          'OTHER'
        ).required(),
        enabled: Joi.boolean(),
        editable: Joi.boolean(),
        heading: Joi.string()
      })
    ),
    customerSnapshotTabs: Joi.array().items(
      Joi.object().keys({
        type: Joi.string().valid(
          'SNAPSHOT_PURCHASES',
          'SNAPSHOT_RECOMMENDATIONS',
          'SNAPSHOT_WISHLIST'
        ).required(),
        enabled: Joi.boolean(),
        label: Joi.string()
      })
    )
  })
})

const schema = Joi.object({
  apps: Joi.object().keys({
    CATALOG: baseSchema,
    CUSTOMER_MANAGEMENT: customerSchema,
    RETAIL_ANALYTICS: baseSchema,
    USER_MANAGEMENT: baseSchema,
    MESSAGING: baseSchema,
    NOTIFICATIONS: baseSchema,
    ORDER_MANAGEMENT: baseSchema,
    POLLS: baseSchema,
    APP_FEEDBACK: baseSchema,
    INSPIRATIONS: baseSchema,
    STOREROOM_KANBAN: baseSchema,
    RESOURCES: baseSchema,
    DEPARTMENTS: departmentsSchema,
    WAITLIST: baseSchema,
    EVENTS: baseSchema,
    CONSULTATIONS: consultationsSchema,
    STORES: baseSchema,
    VIRTUAL_CONSULTATIONS: baseSchema
  }).pattern(/./, baseSchema)
})

export default schema
