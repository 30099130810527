import { connect } from 'react-redux'
import LanguageSelect from './LanguageSelect'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'

const mapStateToProps = state => {
  const selectedLanguage = appSelectors.getAppLanguage(state)
  return {
    selectedLanguage: selectedLanguage
  }
}

const mapDispatchToProps = dispatch => ({
  onLanguageChange: language => {
    dispatch(appActions.changeAppLanguage({ language }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect)
