import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { environment } from '../../config'
import { retailAnalyticsFunctions } from '../dependencies'
import _ from 'lodash'
import {
  selectors as authSelectors
} from '../../store/modules/auth'
import { useSelector } from 'react-redux'

export const RetailAnalytics = () => {
  const region = useSelector(authSelectors.getUserSelectedRegion)
  const sisenseWat = _.get(region, 'details.sisenseWat')

  const implementation = Modules.RetailAnalytics.Implementation.provideUseRetailAnalyticsScreen({
    retailAnalyticsFunctions: retailAnalyticsFunctions,
    sisenseConfig: {
      url: environment.SISENSE_URL,
      watToken: sisenseWat,
      dashboardId: environment.SISENSE_DASHBOARD_ID
    }
  })

  return (
    <Modules.RetailAnalytics.Screens.RetailAnalyticsScreen
      implementation={implementation}
    />
  )
}
