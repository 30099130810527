import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import { Dropdown, Checkbox } from '../../../../../components/Fields'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import P from '../../../../../components/P'
import H2 from '../../../../../components/H2'
import { translations } from '../../../../../config'

import style from './style'

const CommunicationOptions = ({ classes, isStoreMarketingSelected, storesOptions }) => {
  return (
    <ContentBox givenContentClass={classes.communicationContentContainer}>
      <ContentBoxHeader>
        <TextContent><H2 className={classes.contentBoxHeader} value={translations('Communication Options')} /></TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <P className={classes.formText} value={translations('sign up - contact')} />
        <div className={classes.communicationWrapper} >
          <FormGroup row>
            <FormControlLabel
              classes={{
                label: classes.checkboxMargin
              }}
              control={
                <Checkbox
                  name='emailMarketing'
                />
              }
              label={translations('Email')}
              className={classes.noMarginLeft}
            />
            <FormControlLabel
              classes={{
                label: classes.checkboxMargin
              }}
              control={
                <Checkbox
                  name='smsMarketing'
                />
              }
              label={translations('SMS')}
              className={classes.noMarginLeft}
            />
            <FormControlLabel
              classes={{
                label: classes.checkboxMargin
              }}
              control={
                <Checkbox
                  name='postMarketing'
                />
              }
              label={translations('Post')}
              className={classes.noMarginLeft}
            />
          </FormGroup>
        </div>

        <P value={translations('sign up - type')} className={classes.formText} />
        <div className={classes.communicationWrapper} >
          <FormGroup row className={classes.fullWidth}>
            <div>
              <FormControlLabel
                classes={{
                  label: classes.checkboxMargin
                }}
                control={
                  <Checkbox
                    name='generalMarketing'
                  />
                }
                label={translations('General marketing')}
                className={classes.noMarginLeft}
              />
              <FormControlLabel
                classes={{
                  label: classes.checkboxMargin
                }}
                control={
                  <Checkbox
                    name='storeMarketing'
                  />
                }
                label={translations('Store updates')}
                className={classes.noMarginLeft}
              />
              <FormControlLabel
                classes={{
                  label: classes.checkboxMargin
                }}
                control={
                  <Checkbox
                    name='thirdPartyMarketing'
                  />
                }
                label={translations('Third party')}
                className={classes.noMarginLeft}
              />
            </div>
          </FormGroup>
        </div>
        {isStoreMarketingSelected && (
          <div className={classes.storesContainer}>
            <FormControl className={classes.fullWidth}>
              <Dropdown
                strict={'true'}
                options={storesOptions}
                label={translations('Stores for marketing')}
                name='marketingStoreIds'
                multiple
              />
            </FormControl>
          </div>
        )}
      </ContentBoxBody>
    </ContentBox>
  )
}

CommunicationOptions.propTypes = {
  classes: PropTypes.shape({}),
  isStoreMarketingSelected: PropTypes.bool.isRequired,
  storesOptions: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(style)(CommunicationOptions)
