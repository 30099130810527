import { compose, withHandlers } from 'recompose'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'
import { actions as authActions, selectors as authSelectors } from '../../store/modules/auth'
import { authCombinedSelectors } from '../../store/modules/combinedSelectors'
import { environment } from '../../config'

const style = { display: 'flex', flex: 1, flexDirection: 'column' }

const createTimer = function () {
  let timer
  let lastInteractionTime = new Date()
  return {
    start: (getProps) => {
      lastInteractionTime = new Date()

      // If enough time has passed, lock the screen
      const getHasEnoughTimePassed = () => {
        const hasEnoughTimePassed = moment(new Date()).subtract(environment.LOGOUT_TIME, 'milliseconds').isAfter(lastInteractionTime)
        return hasEnoughTimePassed
      }

      // Check if the time difference has passed on a regular interval
      timer = setInterval(() => {
        const { lockScreen, isScreenLocked } = getProps()
        // If screen is already locked, set time to now on each check
        if (isScreenLocked) {
          lastInteractionTime = new Date()
        } else
        if (getHasEnoughTimePassed()) {
          lastInteractionTime = new Date()
          lockScreen()
        }
      }, 2000)
    },
    reset: () => {
      lastInteractionTime = new Date()
    },
    cancel: () => {
      clearInterval(timer)
    }
  }
}

const TimedLogoutHOC = (WrappedComponent) => {
  class TimedLogoutContainer extends Component {
    timer = createTimer()
    eventsToListenFor = [
      'keyup',
      'keydown',
      'focus',
      'focusin',
      'focusout',
      'click',
      'mouseup',
      'mousedown',
      'touchstart',
      'touchmove',
      'touchend',
      'scroll'
    ]

    componentDidMount () {
      // Need to pass a getProps function to ensure
      // the props read in the timeout are never stale
      this.timer.start(() => this.props)

      this.eventsToListenFor.forEach(event => {
        window.addEventListener(event, this.timer.reset, false)
      })
    }

    componentWillUnmount () {
      this.timer.cancel()

      this.eventsToListenFor.forEach(event => {
        window.removeEventListener(event, this.timer.reset)
      })
    }

    render () {
      return (
        <div style={style}>
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
  TimedLogoutContainer.propTypes = {
    logoutTimer: PropTypes.object.isRequired
  }
  const mapStateToProps = (state) => ({
    userIsLoggedIn: authSelectors.getIsLoggedIn(state),
    isScreenLocked: appSelectors.getIsScreenLocked(state),
    isUserLoggedInWithDS: authCombinedSelectors.getIsUserLoggedInWithDS(state)
  })
  return compose(
    connect(mapStateToProps),
    withHandlers({
      lockScreen: ({ isScreenLocked, userIsLoggedIn, isUserLoggedInWithDS, dispatch }) => () => {
        if (isUserLoggedInWithDS) {
          if (userIsLoggedIn && !isScreenLocked) {
            dispatch(appActions.lockScreen({ isLocked: true }))
          }
        } else if (userIsLoggedIn) {
          dispatch(authActions.logout())
        }
      }
    })
  )(TimedLogoutContainer)
}

const IdentityHOC = Comp => props => (
  <Comp {...props} />
)

const ExportedComponent = (
  environment.DISABLE_TIMED_LOGOUT
    ? IdentityHOC
    : TimedLogoutHOC
)

export default ExportedComponent
