import * as constants from './constants'
import reducers from './reducers'

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PURCHASE_REPORTING:
      return reducers.fetchPurchaseReporting(state, action)
    case constants.FETCH_PURCHASE_REPORTING_NEXT_PAGE:
      return reducers.fetchPurchaseReportingNextPage(state, action)
    case constants.FETCH_BIRTHDAY_REPORTING:
      return reducers.fetchBirthdayReporting(state, action)
    case constants.FETCH_PURCHASE_COUNTS_REPORTING:
        return reducers.fetchPurchaseCountsReporting(state, action)
    default:
      return state
  }
}
