import _ from 'lodash'
import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'
import { PENDING } from '../../middleware/redux-promise'
import currencyFormatter from '../../../formatters/currencyFormatter'
import {
  stockLevel as stockLevelConstants,
  showInStoreStockInVariantDropdown,
  translations,
  formNames
} from '../../../config'
import { getAllVariantsFromProducts } from '../products/parseProductGroups'

export const getProduct = state => state.productDetails.product
export const getActiveMediaIndex = state => state.productDetails.mediaIndex
export const getProductCareInstructions = state => _.get(state.productDetails.product, 'details.productCareInstructions', '')
export const getSizeGuide = state => _.get(state.productDetails.product, 'details.productSizeGuide', '')
export const getProductDescriptionSummary = state => _.get(state.productDetails.product, 'details.summary', '')
export const getProductError = state => state.productDetails.error
export const getBottomTab = state => state.productDetails.productTab
export const getSelectedVariantId = state => formValueSelector(formNames.productDetails)(state, 'variantId')

const extractPriceDetails = (product) => ({
  price: _.get(product, 'price.value', 0),
  discount: _.get(product, 'discount.value', 0),
  currency: _.get(product, 'price.code', 'GBP')
})

export const getDisplayPrice = createSelector([
  getProduct
], product => {
  if (!product) return ''
  return getDisplayPriceOfProduct(product)
})
export const getOriginalPrice = createSelector([
  getProduct
], product => {
  if (!product) return ''
  const { price, currency } = extractPriceDetails(product)
  return currencyFormatter.format(price, currency)
})
export const hasDiscount = createSelector([
  getProduct
], product => _.get(product, 'discount.value', 0) > 0)

export const getDiscountPrice = createSelector([
  getProduct
], product => {
  if (!product) return ''
  const { discount, currency } = extractPriceDetails(product)
  return currencyFormatter.format(discount, currency)
})
export const getVariationAttributes = state => {
  const variationAttributes = _.get(state.productDetails, 'product.details.variationAttributes', [])
  return variationAttributes
}

export const getVariationAttributeNames = createSelector([
  getVariationAttributes
], (variationAttributes = []) => {
  return variationAttributes.map(attr => {
    return attr.id
  })
})
export const getVariants = state => {
  const productGroupVariants = getAllVariantsFromProducts(_.get(state.productDetails, 'productGroupProducts', []))

  // Fall back to standard product variants if there aren't any product group variants
  return productGroupVariants.length ? productGroupVariants : _.get(state.productDetails, 'product.variants', [])
}
export const getIsLoading = state => state.productDetails.status === PENDING

const getVariantLabel = (variant = {}, noStock) => {
  const { name, variantStoreStock = {} } = variant
  const { stock } = variantStoreStock
  let result = name
  if (showInStoreStockInVariantDropdown && !noStock && stock) {
    let stockText = ''
    const addStockNumberTostockText = () => {
      stockText += ` - ${stock}`
    }
    if (stock >= stockLevelConstants.inStockLevel) {
      stockText = translations('In Stock')
      addStockNumberTostockText()
    } else if (stock >= stockLevelConstants.lowStockLowLevel) {
      stockText = translations('Low Stock')
      addStockNumberTostockText()
    } else if (stock === stockLevelConstants.outOfStockLevel) {
      stockText = translations('Out Of Stock')
    } else if (stock === stockLevelConstants.itemNotStocked) {
      stockText = translations('Not Stocked')
    } else {
      stockText = translations('Unknown Stock')
    }
    result = `${name} (${stockText})`
  }

  // Special case for product groups
  if (typeof variant.price === 'object') {
    const price = getDisplayPriceOfProduct(variant)
    result += ` (${price})`
  }

  return result
}
export const getOptionsFromVariants = (variants, noStock) => {
  return _.chain(variants)
    .sortBy('variantOrder')
    .map(variant => ({
      label: getVariantLabel(variant, noStock),
      value: variant.id
    }))
    .value()
}

export const getVariantsOptions = createSelector(getVariants, getOptionsFromVariants)

export const getDisplayPriceOfProduct = (product) => {
  const { price, discount, currency } = extractPriceDetails(product)
  const displayPrice = price - discount
  return currencyFormatter.format(displayPrice, currency)
}

export const getSaveAmount = (product) => {
  const { discount, currency } = extractPriceDetails(product)
  return currencyFormatter.format(discount, currency)
}

export const getOriginalProductPrice = (product) => {
  const { price, currency } = extractPriceDetails(product)
  return currencyFormatter.format(price, currency)
}

export const getOnlineAvailabilty = createSelector([
  getVariants, getSelectedVariantId
], (variants, selectedVariantId) => {
  if (selectedVariantId) {
    return _.chain(variants)
      .find(variant => variant.id === selectedVariantId)
      .get('onlineStock')
      .value()
  } else {
    const defaultStockWeight = stockLevelConstants.stockStatusWeight[stockLevelConstants.ONLINE_UNAVAILABLE_STATUS]
    const bestStockStatusValue = _.reduce(variants, (memo, variant) => {
      const stockStatusValue = stockLevelConstants.stockStatusWeight[variant.onlineStock]
      return (stockStatusValue > memo) ? stockStatusValue : memo
    }, defaultStockWeight)

    const stockStatus = _.findKey(stockLevelConstants.stockStatusWeight, (weight) => weight === bestStockStatusValue)

    return stockStatus
  }
})
export const getRelatedProducts = createSelector([
  getProduct
], product => {
  return _.get(product, 'reporting.relatedProducts', [])
})
