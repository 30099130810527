import * as validators from '../../../validators'
import {
  countries,
  countryPhoneCodes
} from '../../../config'

import CustomerEmailField from '../../../containers/Customer/CustomerEditableFields/Email'
import CustomerPhoneNumberField from '../../../containers/Customer/CustomerEditableFields/PhoneNumber'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const dobAgeValidator = validators.dobMinAge(16)

export default [{
  id: 'title',
  field: 'Dropdown',
  props: {
    label: 'Title',
    name: 'title',
    options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
    validate: [smallTextLengthValidator],
    required: false
  }
}, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: 'First Name',
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: 'Last Name',
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'companyName',
  field: 'Input',
  props: {
    label: 'Company Name',
    name: 'companyName',
    required: false
  }
}, {
  id: 'telephone',
  component: CustomerPhoneNumberField,
  props: {
    label: 'Phone Number',
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: true
  }
}, {
  id: 'address1',
  field: 'Input',
  props: {
    label: 'Address Line One',
    name: 'address.address1',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'address2',
  field: 'Input',
  props: {
    label: 'Address Line Two',
    name: 'address.address2',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'city',
  field: 'Input',
  props: {
    label: 'City',
    name: 'address.city',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'county',
  field: 'Input',
  props: {
    label: 'County',
    name: 'address.county',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'country',
  field: 'Dropdown',
  props: {
    label: 'Country',
    name: 'address.country',
    validate: [smallTextLengthValidator],
    options: countries
  }
}, {
  id: 'postCode',
  field: 'Input',
  props: {
    label: 'Customer Postcode',
    name: 'address.postCode',
    validate: [smallTextLengthValidator]
  }
}]
