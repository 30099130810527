import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { getPriceValue } from '@redant/digital-store-prices-loccitane'

import { selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails'
import { formatDate } from '../../../../../helpers'
import { getDeliveryText, getCustomerFullName, getFullName } from '../../../../../helpers'
import { translations, noPrices, deliveryEnabled, manualDiscountsEnabled } from '../../../../../config'
import currencyFormatter from '../../../../../formatters/currencyFormatter'

import OrderFooter from './OrderFooter'

const cf = currencyFormatter.format

const mapStateToProps = (state) => {
  const order = orderDetailsSelectors.getOrder(state)
  return { order }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['order'],
    ({ order }) => {
      const {
        _store,
        customer,
        customerId,
        deliveryDetails,
        deliveryOption,
        deliveryPrice,
        orderType,
        salesChannel,
        subTotal,
        tax,
        total,
        totalDiscount,
        user
      } = order || {}

      const noCustomer = (!customerId && (_.isEmpty(customer) || !customer))

      const orderInfo = []
      orderInfo.push(['channel', salesChannel])

      if (deliveryEnabled && orderType !== 'storeroom') orderInfo.push(['deliveryOption', getDeliveryText(noCustomer ? null : deliveryOption)])
      if (deliveryEnabled && _.get(deliveryDetails, 'store')) orderInfo.push(['deliveryStore', _.get(deliveryDetails, 'store.name')])
      if (_store) orderInfo.push(['store', _store.name])
      if (user) orderInfo.push(['user', getFullName(user)])
      if (manualDiscountsEnabled && orderType !== 'storeroom') {
        orderInfo.push(['subTotal', cf(subTotal)])
        orderInfo.push(['discount', cf(totalDiscount)])
      }
      if (getPriceValue(tax)) orderInfo.push(['tax', cf(tax)])
      if (deliveryEnabled && deliveryOption) orderInfo.push(['deliveryCost', cf(deliveryPrice)])
      orderInfo.push(['total', cf(total)])

      return { orderInfo }
    }
  )
)(OrderFooter)
