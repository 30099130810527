import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { appCommunications as appCommunicationsConfig } from '../../../../config'

import AppCommunications from './AppCommunications'

const mapStateToProps = (state, ownProps) => {
  const customerId = _.get(ownProps, 'initialValues.id')
  const appCommunications = _.get(ownProps, 'initialValues.appCommunications', {})
  
  const communicationOptionsMap = _.keyBy(appCommunicationsConfig.communicationOptions, 'value');
  
  const appCommunicationOptions = Object.keys(appCommunications).filter((key) => appCommunications[key])
  const appCommunicationValues = _.map(appCommunicationOptions, (value) => _.get(communicationOptionsMap[value], 'label'))
  return {
    initialValues: {
      id: customerId,
      appCommunicationOptions
    },
    displayValues: appCommunicationValues 
  }
}

export default compose(
  connect(mapStateToProps)
)(AppCommunications)
