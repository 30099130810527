import _ from 'lodash'
import { createSelector } from 'reselect'
import { translations } from '../../../config'

import { PENDING } from '../../middleware/redux-promise'

export const getOrders = state => state.orders.results

export const getIsLoading = state => state.orders.status === PENDING

export const getQuery = state => state.orders.query
export const getPage = state => state.orders.page
export const getSize = state => state.orders.size
export const getStartDate = state => state.orders.startDate
export const getEndDate = state => state.orders.endDate
export const getUserId = state => state.orders.userId
export const getStoreId = state => state.orders.storeId
export const getOrderType = state => state.orders.orderType

export const getHasMore = state => (
  state.orders.total !== null &&
  state.orders.results.length < state.orders.total
)
export const getHasSearched = state => !!state.orders.status
export const getHasNoResults = state => {
  const hasSearched = getHasSearched(state)
  const isLoading = getIsLoading(state)
  const orders = getOrders(state)
  return hasSearched && !isLoading && (!orders || !orders.length)
}

export const getOrderTypes = state => _.get(state.orders, 'orderTypes', [])

export const getOrderTypesAsOptions = createSelector([getOrderTypes], orderTypes => {
  return orderTypes.map(type => ({ label: translations(`Order type - ${type}`), value: type }))
})
