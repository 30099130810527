import { connect } from 'react-redux'
import { change } from 'redux-form'
import _ from 'lodash'
import { selectors as createMessageSelectors, actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { actions as customerDetailsActions } from '../../../../../store/modules/customerDetails'
import { actions as customersActions } from '../../../../../store/modules/customers'
import modalService from '../../../../../services/modalService'
import { MessengerEditorRecipients } from '../../../../../components/Messenger'
import RecipientsSearchModal from './RecipientsSearchModal'

import { formId } from '../../MessageEditorForm'

const mapStateToProps = state => {
  const currentRecipients = createMessageSelectors.getCurrentRecipients(state)

  return {
    currentRecipients,
    searchTypeName: 'customers'
  }
}

const mapDispatchToProps = dispatch => {
  const showSearchRecipientsModal = () => {
    dispatch(customersActions.resetStore())
    modalService.open({
      component: RecipientsSearchModal,
      fullScreen: true,
      onCustomerClick: async (customer) => {
        const fullCustomerResponse = await dispatch(customerDetailsActions.fetchFullCustomer(customer.id))
        const fullCustomer = _.get(fullCustomerResponse, 'customer')
        if (fullCustomer) {
          const customerMarketingChannels = []

          if (_.get(fullCustomer, 'smsMarketing') === true) {
            customerMarketingChannels.push('smsMarketing')
          }
          if (_.get(fullCustomer, 'emailMarketing') === true) {
            customerMarketingChannels.push('emailMarketing')
          }
          if (_.get(fullCustomer, 'postMarketing') === true) {
            customerMarketingChannels.push('postMarketing')
          }
          dispatch(createMessageActions.updateReceipient({
            ...customer,
            telephone: fullCustomer.telephone,
            customerMarketingChannels
          }))
          modalService.close()
        } else {
          dispatch(createMessageActions.updateReceipient(customer))
          dispatch(change(formId, 'communicationType', customer.telephone ? 'sms' : 'email'))
        }
      },
      onBackClick: () => modalService.close()
    })
  }

  return {
    showSearchRecipientsModal
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessengerEditorRecipients)
