import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Chip from '../../../../components/Chip'
import { translations } from '../../../../config'

const ChipsContainer = styled.div`
  text-align: center;
  padding: 0 10px;
  > div { margin-top: 10px; }
  > div + div { margin-left: 5px; }
`

const SelectedFilters = ({ activeFilters, handleDeleteFilter, noChipFilters = [] }) => {
  const activeFiltersArray = (activeFilters) ? activeFilters.split(',') : []
  const chips = _.filter(activeFiltersArray, (item) => !_.includes(noChipFilters, item))
  return (
    <ChipsContainer>
        {chips.length > 0 && (
        <>
            {_.map(chips, (value) => {
            return <Chip
                key={value}
                type={'delete'}
                onClick={() => handleDeleteFilter(value)}
                >
                  {translations(value)}
                </Chip>
            })}
            </>
        )}
    </ChipsContainer>
  )
}

export default SelectedFilters