import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'connected-react-router'

import { actions as checkoutFlowActions, selectors as checkoutFlowSelectors } from '../../store/modules/checkoutFlow'
import { actions as currentOrderActions } from '../../store/modules/currentOrder'
import { selectors as currentAppointmentSelectors } from '../../store/modules/currentAppointment'
import * as currentOrderCombinedSelectors from '../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import withLoader from '../../components/FullScreenLoader'
import ErrorScreen from '../../components/ErrorScreen'
import { translations } from '../../config'

const mapStateToProps = state => {
  const currentOrder = currentOrderCombinedSelectors.currentOrderSelector(state)
  const checkoutFlow = checkoutFlowSelectors.getAll(state)
  const isVirtualConsultation = currentAppointmentSelectors.getAppointmentType(state) === 'VIRTUAL_CONSULTATION'

  return {
    ...currentOrder,
    ...checkoutFlow,
    isVirtualConsultation
  }
}

const mapDispatchToProps = dispatch => ({
  next: () => dispatch(checkoutFlowActions.next()),
  fail: () => dispatch(checkoutFlowActions.fail()),
  setDeferredModule: (moduleName) => dispatch(checkoutFlowActions.setDeferredModule(moduleName)),
  updateOrder: params => dispatch(currentOrderActions.updateOrder(params)),
  stopEditingOrder: () => {
    dispatch(checkoutFlowActions.end())
    dispatch(currentOrderActions.stopEditingOrder())
  },
  goToHome: () => dispatch(push('/')),
  dispatch
})

const withCheckoutCheck = Comp => (
  class WithCheckoutCheck extends Component {
    render () {
      const { current, groupedProducts, goToHome } = this.props
      if (current && groupedProducts.length) {
        return <Comp {...this.props} />
      } else {
        // redirecting would be ideal but this causes problems
        // when going back from checkout (current is updated in redux
        // store before page changes)
        // return <Redirect to='/' />
        return <ErrorScreen
          title={translations('Checkout Error Page Title')}
          buttonText={translations('Checkout Error Page Button')}
          onClick={goToHome}
        />
      }
    }
  }
)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCheckoutCheck,
  withLoader
)
