import React, { useEffect, useState } from 'react'
import Dropdown from '../../../../components/Fields/Dropdown'
import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import _ from 'lodash'

const GridItem = styled(Grid)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const FilterDropdown = styled(Dropdown)`
  width: 100%;
`

const ReportingFiltersModal = ({ onConfirm, currentFilterBy }) => {
  const [lastPurchaseDateFilter, setLastPurchaseDateFilter] = useState()
  const [orderTypeFilter, setOrderTypeFilter] = useState()
  const [currentLastPurchaseDateFilter, setCurrentLastPurchaseDateFilter] = useState()
  const [currentOrderTypeFilter, setCurrentOrderTypeFilter] = useState()

  const orderTypeOptions = [
    {label: translations('All'), value: 'order_type_all'},
    {label: translations('order_type_web'), value: 'order_type_web'},
    {label: translations('order_type_my_store'), value: 'order_type_my_store'}
  ]

  const lastPurchaseOptions = [
    {label: translations('From 2 to 13 day'), value: 'last_purchase_date_two_to_thirteen'},
    {label: translations('From 14 to 28 day'), value: 'last_purchase_date_fourteen_to_twentyeight'}
  ]

  useEffect(() => {
    if (currentFilterBy) {
      const filters = currentFilterBy.split(',')
      const orderTypes = _.map(orderTypeOptions, (item) => item.value)
      const lastPurchaseTypes = _.map(lastPurchaseOptions, (item) => item.value)
      setCurrentLastPurchaseDateFilter(_.find(filters, (item) => _.includes(lastPurchaseTypes, item)))
      setCurrentOrderTypeFilter(_.find(filters, (item) => _.includes(orderTypes, item)))
    }
  }, [currentFilterBy])

  return (
    <Grid container>
      <GridItem item xs={12}>
        <FilterDropdown
          label={translations('Order type')}
          value={orderTypeFilter || currentOrderTypeFilter}
          options={orderTypeOptions}
          onChange={(e) => setOrderTypeFilter(e.target.value)}
          noErrorTextLabel
        />
      </GridItem>
      <GridItem item xs={12}>
        <FilterDropdown
          label={translations('Last purchase date')}
          value={lastPurchaseDateFilter || currentLastPurchaseDateFilter}
          options={lastPurchaseOptions}
          onChange={(e) => setLastPurchaseDateFilter(e.target.value)}
          noErrorTextLabel
        />
      </GridItem>
      <GridItem item xs={12}>
        <Button 
          color='primary'
          fullWidth
          onClick={() => onConfirm({ lastPurchaseDateFilter: lastPurchaseDateFilter || currentLastPurchaseDateFilter, orderTypeFilter: orderTypeFilter || currentOrderTypeFilter })}
        >{translations('Confirm')}</Button>
      </GridItem>
    </Grid>
  )
}

export default ReportingFiltersModal
