import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import style from './style'
import LoginForm from '../LoginForm'
import { translations, auth, colors } from '../../../config'
import MainLogo from '../../../components/MainLogo'
import Button from '../../../components/Button'
import RecentUsers from '../RecentUsers'
import LanguageSelect from '../../../components/LanguageSelect'

const OfflineMessage = styled.div`
  padding: 8px 12px;
  color: white;
  text-align: center;
  margin: 6px 0 14px 0;
  background-color: ${colors.error};
  font-size: 14px;
  font-weight: 600;
`

const SSOLogin = ({
  classes,
  openSSO,
  setLoginMethod
}) => (
  <div className={classes.contentContainer}>
    <div className={classes.formContainer}>
      <Button buttonType={'primary'} fullWidth onClick={openSSO}>
        {translations('SSO Login Submit')}
      </Button>
      <div className={classes.linkWrapper}>
        <Button onClick={() => setLoginMethod('DIGITAL_STORE')} className={classes.hyperlinkButton} >
          {translations('Digital Store login')}
        </Button>
      </div>
    </div>
  </div>
)

const StandardLogin = ({
  classes,
  success,
  isUsingRecentUser,
  hideRecentUsers,
  setIsUsingRecentUser,
  hideForgotPassword,
  enabledSSO,
  accountIsLocked,
  showForgotPasswordModal,
  setLoginMethod,
  pushToForgotPassword
}) => (
  <div className={classes.contentContainer}>
    {(auth.recentUsers && !hideRecentUsers) && (
      <RecentUsers
        className={classes.recentUsers}
        onClick={() => setIsUsingRecentUser(true)}
      />
    )}
    <div className={classes.formContainer}>
      <LoginForm success={success} isUsingRecentUser={isUsingRecentUser} />
      <div className={classes.linkWrapper}>
        {!hideForgotPassword &&
          <Button onClick={pushToForgotPassword} className={classes.hyperlinkButton}>
            {translations('forgot your password')}
          </Button>
        }
        {enabledSSO && <Button onClick={() => setLoginMethod('SSO')} className={classes.hyperlinkButton}>
          {translations('Sign in with SSO')}
        </Button>}
      </div>
      {hideForgotPassword &&
        <div className={classes.linkWrapper}>
          {accountIsLocked
            ? <Button onClick={showForgotPasswordModal} className={classes.hyperlinkButton} >
              {translations('Sign out')}
            </Button>
            : <Button onClick={showForgotPasswordModal} className={classes.hyperlinkButton} >
              {translations('forgot your password')}
            </Button>
          }
        </div>
      }
    </div>
  </div>
)

const LoginScreen = (props) => {
  const { loginMethod, enabledSSO, classes, networkConnectivity } = props
  return (
    <div className={classes.container}>
      <div className={classes.languageSelectContainer}>
        <LanguageSelect />
      </div>
      <div className={classes.innerContainer}>
        <MainLogo className={networkConnectivity ? classes.logo : classes.logoNoMargin} />
        {!networkConnectivity && (
          <OfflineMessage>{translations('You are in offline mode, therefore can only sign in using recent users.')}</OfflineMessage>
        )}
        {loginMethod === 'DIGITAL_STORE' && <StandardLogin {...props} />}
        {enabledSSO && loginMethod === 'SSO' && <SSOLogin {...props} />}
      </div>
    </div>
  )
}

LoginScreen.propTypes = {
  hideForgotPassword: PropTypes.bool

}

export default withStyles(style)(LoginScreen)
