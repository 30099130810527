import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { Checklist, Dropdown } from '../../../../components/EditableFields'
import { appCommunications, translations } from '../../../../config'

const AppCommunicationsForm = FormContentBox('appCommunications')

const AppCommunications = Editable(({ initialValues, displayValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, extraProps, editable }) => {  
  const displayValue = displayValues.length > 1 ? displayValues.join(', ') : displayValues.join('')
  return (
    <div>
      <AppCommunicationsForm
        enableReinitialize
        initialValues={initialValues}
        editing={editing}
        editable={editable}
        toggleEdit={toggleEdit}
        isSubmitting={isSubmitting}
        boxName={boxName}
        onSubmit={onSubmit}
        formId={formId}
        extraProps={extraProps}
      >
        <FormError />
        <FormBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Checklist
                editing={editing}
                initialValues={initialValues}
                options={appCommunications.communicationOptions}
                name='appCommunicationOptions'
                label='Preferences'
                multiple
                displayValue={displayValue}
              />
            </Grid>
          </Grid>
        </FormBody>
      </AppCommunicationsForm>
    </div>
  )
})

export default AppCommunications

