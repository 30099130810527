import * as validators from '../../../validators'
import {
  countries,
  countryPhoneCodes,
  translations
} from '../../../config'

import CustomerEmailField from '../../../containers/Customer/CustomerEditableFields/Email'
import CustomerPhoneNumberField from '../../../containers/Customer/CustomerEditableFields/PhoneNumber'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const dobAgeValidator = validators.dobMinAge(16)

export default [{
  id: 'title',
  field: 'Dropdown',
  props: {
    label: 'Title',
    name: 'title',
    options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
    validate: [smallTextLengthValidator],
    required: false
  }
}, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: 'First Name',
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: 'Last Name',
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'telephone',
  component: CustomerPhoneNumberField,
  props: {
    label: 'Phone Number',
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: true
  }
}, {
  id: 'email',
  component: CustomerEmailField,
  props: {
    label: 'Email',
    name: 'email',
    validate: [validators.email, smallTextLengthValidator],
    required: true,
    showEmailButton: true
  }
}, {
  id: 'gender',
  field: 'Dropdown',
  props: {
    label: 'Gender',
    name: 'gender',
    options: ['Male', 'Female', 'Other'],
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'dob',
  field: 'DatePicker',
  props: {
    label: 'Date of Birth',
    name: 'dob',
    validate: [validators.date, dobAgeValidator]
  }
}, {
  id: 'county',
  field: 'Input',
  props: {
    label: 'County',
    name: 'address.county',
    validate: [smallTextLengthValidator],
    editable: false
  }
}, {
  id: 'country',
  field: 'Dropdown',
  props: {
    label: 'Country',
    name: 'address.country',
    validate: [smallTextLengthValidator],
    options: countries,
    editable: false
  }
}]
