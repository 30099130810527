import React, { Fragment } from 'react'
import { compose, pure } from 'recompose'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import style from './style'
import PersonalFields from './PersonalFields'
import CheckoutWrapper from '../common/CheckoutWrapper'
import { translations } from '../../../config'
import Form from '../../../components/Form'
import { Radiolist, Dropdown } from '../../../components/Fields'
import H2 from '../../../components/H2'
import Address from '../../../components/Address'
import * as validators from '../../../validators'
import P from '../../../components/P'

export const formId = 'delivery-options'

const DeliveryForm = Form(formId)

const typeOptions = [
  { value: 'home', label: translations('Home Delivery') },
  { value: 'store', label: translations('Collect from Store') }
]

const Delivery = props => {
  const {
    classes,
    deliveryType,
    hasAddressPrefilled,
    homeOptions,
    storeDropdownOptions,
    storeOptions,
    onChangeDeliveryType,
    onChangeDeliveryOption,
    onSubmit,
    initialValues
  } = props
  const titleClass = classNames(classes.title, classes.deliveryTitle)
  const firstBoxInnerClass = classNames(classes.inner, classes.radioInner, classes.firstBoxInner)
  const secondBoxInnerClass = classNames(classes.inner, classes.radioInner, classes.secondBoxInner)

  return (
    <DeliveryForm
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <CheckoutWrapper
        formId={formId}
        editable
      >
        {
          props.customer
            ? <div>
              {
                storeOptions && storeOptions.length
                  ? <div className={classes.container}>
                    <div className={firstBoxInnerClass}>
                      <H2
                        value={translations('Delivery')}
                        className={titleClass}
                      />
                      <FormControl>
                        <Radiolist
                          name='deliveryType'
                          options={typeOptions}
                          onChange={onChangeDeliveryType}
                          radioClassName={classes.radioList}
                        />
                      </FormControl>
                    </div>
                  </div>
                  : null
              }
              {
                deliveryType === 'home'
                  ? (
                    <Fragment>
                      <div className={classes.container}>
                        {
                          homeOptions.length
                            ? <div className={secondBoxInnerClass}>
                              <FormControl>
                                <Radiolist
                                  name='deliveryOptionHome'
                                  options={homeOptions}
                                  onChange={onChangeDeliveryOption}
                                  radioClassName={classes.radioList}
                                />
                              </FormControl>
                            </div>
                            : null
                        }
                      </div>
                      <div className={classes.container}>
                        <div className={classes.inner}>
                          <P
                            value={translations('required fields')}
                            className={classes.requiredInfo}
                          />
                          <Grid container spacing={2}>
                            <PersonalFields />
                            <Address
                              fullWidth
                              formName={formId}
                              noContentBox
                              required
                              alwaysShowFields={hasAddressPrefilled}
                            />
                          </Grid>
                        </div>
                      </div>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <div className={classes.container}>
                        {
                          storeOptions.length
                            ? <div className={secondBoxInnerClass}>
                              <FormControl>
                                <Radiolist
                                  name='deliveryOptionStore'
                                  options={storeOptions}
                                  onChange={onChangeDeliveryOption}
                                  radioClassName={classes.radioList}
                                />
                              </FormControl>
                            </div>
                            : null
                        }
                      </div>
                      <div className={classes.container}>
                        <div className={classes.inner}>
                          <P
                            value={translations('required fields')}
                            className={classes.requiredInfo}
                          />
                          <FormControl className={classes.field} required>
                            <Dropdown
                              label={translations('Store')}
                              name='storeId'
                              options={storeDropdownOptions}
                              validate={validators.required}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </Fragment>
                  )
              }
            </div>
            : null
        }
      </CheckoutWrapper>
    </DeliveryForm>
  )
}

export default withStyles(style)(Delivery)
