import _ from 'lodash'
import { connect } from 'react-redux'

import { selectors as storesSelectors } from '../../../store/modules/stores'
import StoreDropdown from './StoreDropdown'

const mapStateToProps = state => {
  const stores = storesSelectors.getAllStores(state)

  const options = _.map(stores, store => ({
    label: store.name,
    value: store.id
  }))

  return { options }
}

export default connect(mapStateToProps)(StoreDropdown)
