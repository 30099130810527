import CustomerIcon from './icon-customer.svg'
import ProductIcon from './icon-product.svg'
import WebPortalIcon from './icon-web-portal.svg'
import EmailIcon from './icon-email.svg'
import PhoneIcon from './icon-phone.svg'
import UserManagementIcon from './icon-user-management.svg'
import DeactivatedIcon from './icon-deactivated.svg'
import AnalyticsIcon from './icon-retail-analytics.svg'
import CloseIcon from './icon-close.png'
import MoreIcon from './icon-more.svg'
import BasketIcon from './icon-basket.png'
import TrashIcon from './icon-trash-dark.svg'
import TrashIconPrimary from './icon-trash-primary.svg'
import LoveEnabledIcon from './icon-love-enabled.svg'
import LoveDisabledIcon from './icon-love-disabled.svg'
import SmsIcon from './icon-sms-dark.svg'
import ScanIcon from './icon-scan-light.svg'
import WishlistIcon from './icon-add-to-wishlist.svg'
import WishlistSelectedIcon from './icon-add-to-wishlist-selected.svg'
import MessageIcon from './icon-add-to-message.svg'
import CloseIconBlack from './icon-close-black.svg'
import FileIcon from './icon-file.svg'
import ShirtSizeIcon from './icon-shirt-size.svg'
import OrderManagementIcon from './icon-order-management.svg'
import ChevronDownIcon from './icon-chevron-down-white.svg'
import CommentsIconPrimary from './icon-comments-primary.svg'
import greenTickIcon from './icon-tick-green.svg'
import redCancelIcon from './icon-cancel-red.svg'
import addToBasketIcon from './icon-add-to-basket.svg'
import staffBadgeIcon from './icon-staff-badge.svg'
import gearIcon from './icon-gear.svg'
import ChevronIcon from './icon-chevron-primary.svg'
import WizardAddIcon from './icon-wizard-add.svg'
import WizardTickIcon from './icon-wizard-tick.svg'
import WizardViewIcon from './icon-wizard-view.svg'
import RefundIcon from './icon-refund.svg'

import addToWaitlistIcon from './icon-add-to-waitlist.svg'
import viewWaitlistIcon from './icon-view-waitlist.svg'

import ApptIcon from './icon-appointments.svg'
import InspirationIcon from './icon-inspiration.svg'
import NewMessageIcon from './icon-new-message.svg'
import NotificationsIcon from './icon-notifications.svg'
import PickFromStoreIcon from './icon-pick-from-store.svg'
import teamsIcon from './icon-teams.svg'
import messageDarkIcon from './icon-message-dark.svg'
import GreenCheckIcon from './icon-check-circle-green.svg'
import OrangeCheckIcon from './icon-check-circle-orange.svg'
import RedCrossIcon from './Icon-cross-circle-red.svg'
import BlackCrossIcon from './Icon-cross-circle-black.svg'
import LocationIcon from './icon-location.svg'
import FilterIcon from './icon-filter.svg'
import PollIcon from './polls-icon-large.png'

import virtualAppointmentIcon from './icon-virtual-appointment.svg'
import storeAppointmentIcon from './icon-store-appointment.svg'
import notesIcon from './icon-notes.svg'

import ProductImg from './tiles/product.png'
import AppointmentsImg from './tiles/appointments.png'
import InspirationImg from './tiles/inspiration.png'
import MyClientBook from './tiles/my-client-book.png'
import NotificationsImg from './tiles/notifications.png'
import PickFromStoreImg from './tiles/pick-from-store.png'
import RetailAnalyticsImg from './tiles/retail-analytics.png'
import UserManagementImg from './tiles/user-management.png'
import WebPortalImg from './tiles/web-portal.png'
import NewMessageImg from './tiles/new-message.png'
import PollImg from './tiles/polls.png'
import OrderManagementImg from './tiles/order-management.png'
import appFeedback from './tiles/app-feedback.png'
import EventsImg from './tiles/tile-events.png'
import ConsultationsImg from './tiles/tile-consultation.png'
import ProductWaitlistImg from './tiles/tile-product-waitlist.png'
import StoreManagementTile from './tiles/tile-store-management.png'
import VirtualConsultationTile from './tiles/tile-virtual-consultation.png'
import RemoteSalesTile from './tiles/tile-remote-sales.png'
import StockRefilTile from './tiles/tile-stock-refil.png'
import AlterationsTile from './tiles/tile-alterations.png'
import ReserveOrderTile from './tiles/tile-reserve-and-order.png'
import myClientBookFR from './tiles/tile-clients-fr.jpg'
import productImgFR from './tiles/tile-products-fr.jpg'
import retailAnalyticsImgFR from  './tiles/tile-retail-analytics-fr.jpg'
import productWaitlistImgFR from './tiles/tile-waitlist-fr.jpg'
import orderManagementImgFR from './tiles/tile-order-managment-fr.jpg'
import newMessageImgFR from './tiles/tile-message-fr.jpg'
import notificationsImgFR from './tiles/tile-notifications-fr.jpg'
import appFeedbackFR from './tiles/tile-app-feedback-fr.jpg'

import VideoPlayIconImg from './video-play-Icon.svg'
import BlankImg from './1x1.png'
import ImageNotAvailableImg from './image-not-available.png'
import emoji1 from './emoji-1.png'
import emoji2 from './emoji-2.png'
import emoji3 from './emoji-3.png'
import emoji4 from './emoji-4.png'
import emoji5 from './emoji-5.png'
import scale1 from './scale-1.png'
import scale2 from './scale-2.png'
import scale3 from './scale-3.png'
import scale4 from './scale-4.png'
import scale5 from './scale-5.png'
import thumbDown from './thumb-down.png'
import thumbUp from './thumb-up.png'
import checkCircle from './check-circle.png'
import addFromGallery from './icon-add-from-gallery.svg'
import camera from './icon-camera.svg'
import iconAppFeedback from './icon-app-feedback.svg'
import basketIconWhite from './icon-basket-white.svg'
import userToUserNotificationIcon from './user-to-user-notification.svg'
import OrientationHelperLandscape from './ds-rotate-landscape.png'
import BasketPlus from './plus-to-basket.svg'
import InfoIcon from './info-icon.svg'

const icons = {
  emailIcon: EmailIcon,
  phoneIcon: PhoneIcon,
  productImg: ProductImg,
  appointmentImg: AppointmentsImg,
  inspirationImg: InspirationImg,
  myClientBook: MyClientBook,
  notificationsImg: NotificationsImg,
  pickFromStoreImg: PickFromStoreImg,
  retailAnalyticsImg: RetailAnalyticsImg,
  userManagementImg: UserManagementImg,
  storeManagementTile: StoreManagementTile,
  virtualConsultationTile: VirtualConsultationTile,
  alterationsTile: AlterationsTile,
  reserveOrderTile: ReserveOrderTile,
  remoteSalesTile: RemoteSalesTile,
  stockRefilTile: StockRefilTile,
  webPortalImg: WebPortalImg,
  newMessageImg: NewMessageImg,
  deactivatedIcon: DeactivatedIcon,
  closeIcon: CloseIcon,
  moreIcon: MoreIcon,
  messageDarkIcon,
  basketIcon: BasketIcon,
  trashIcon: TrashIcon,
  trashIconPrimary: TrashIconPrimary,
  smsIcon: SmsIcon,
  scanIcon: ScanIcon,
  videoPlayIcon: VideoPlayIconImg,
  wishlistIcon: WishlistIcon,
  wishlistSelectedIcon: WishlistSelectedIcon,
  messageIcon: MessageIcon,
  closeIconBlack: CloseIconBlack,
  fileIcon: FileIcon,
  shirtSizeIcon: ShirtSizeIcon,
  greenCheckIcon: GreenCheckIcon,
  orangeCheckIcon: OrangeCheckIcon,
  redCrossIcon: RedCrossIcon,
  blackCrossIcon: BlackCrossIcon,
  locationIcon: LocationIcon,
  blankImg: BlankImg,
  pollImg: PollImg,
  imageNotAvailableImg: ImageNotAvailableImg,
  orderManagementImg: OrderManagementImg,
  eventsImg: EventsImg,
  productWaitlistImg: ProductWaitlistImg,
  consultationsImg: ConsultationsImg,
  chevronDownIcon: ChevronDownIcon,
  chevronIcon: ChevronIcon,
  wizardAddIcon: WizardAddIcon,
  wizardTickIcon: WizardTickIcon,
  wizardViewIcon: WizardViewIcon,
  refundIcon: RefundIcon,
  filterIcon: FilterIcon,
  commentsIconPrimary: CommentsIconPrimary,
  LoveEnabledIcon,
  LoveDisabledIcon,
  emoji1,
  emoji2,
  emoji3,
  emoji4,
  emoji5,
  scale1,
  scale2,
  scale3,
  scale4,
  scale5,
  thumbDown,
  thumbUp,
  checkCircle,
  camera,
  addFromGallery,
  appFeedback,
  greenTickIcon,
  redCancelIcon,
  addToBasketIcon,
  basketIconWhite,
  userToUserNotificationIcon,
  staffBadgeIcon,
  addToWaitlistIcon,
  viewWaitlistIcon,
  gearIcon,
  virtualAppointmentIcon,
  storeAppointmentIcon,
  notesIcon,
  basketPlus: BasketPlus,
  infoIcon: InfoIcon,
  myClientBookFR,
  productImgFR,
  retailAnalyticsImgFR,
  productWaitlistImgFR,
  orderManagementImgFR,
  newMessageImgFR,
  notificationsImgFR,
  appFeedbackFR
}

const cornerIcon = {
  productIcon: ProductIcon,
  customerIcon: CustomerIcon,
  webPortalIcon: WebPortalIcon,
  userManagementIcon: UserManagementIcon,
  analyticsIcon: AnalyticsIcon,
  apptIcon: ApptIcon,
  inspirationIcon: InspirationIcon,
  newMessageIcon: NewMessageIcon,
  notificationIcon: NotificationsIcon,
  pickStoreIcon: PickFromStoreIcon,
  teamsIcon: teamsIcon,
  pollIcon: PollIcon,
  orderManagementIcon: OrderManagementIcon,
  iconAppFeedback,
  orientationHelperLandscape: OrientationHelperLandscape
}
export const getImage = name => {
  return icons[name] || cornerIcon[name]
}
