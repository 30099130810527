import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { compose } from 'recompose'

import SubHeader from '../../../components/SubHeader'
import FormContentBox from '../../../components/FormContentBox'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import styles from './style'
import * as schema from './schema'
import FullScreenProgress from '../../../components/FullScreenLoader'

import WrappedFormContentBox from './WrappedFormContentBox'
import LatestNote from './LatestNote'
import LatestMessage from './LatestMessage'
import RecommendedProducts from './RecommendedProducts'
import Loyalty from './Loyalty/OrderList'
import CommunicationOptions from './CommunicationOptions'
import AppCommunications from './AppCommunications'
import Wishlist from './Wishlist'
import CustomerWaitlist from './CustomerWaitlist'

import AppointmentsList from '../../ConsultationsV2/Consultation/AppointmentsDashboardScreen/AppointmentsListing/AppointmentsList'

import { translations, getAppConfig } from '../../../config'
import Follow from './Follow'
import TopRightButton from '../../../components/TopRightButton'
import { getCustomerFullName } from '../../../helpers'
import RequiredTextMessage from '../../../components/RequiredTextMessage'

const PersonalDetailsForm = FormContentBox('personal-details')
const AddressDetailsForm = FormContentBox('address-details')
const CustomerDetailsForm = FormContentBox('customer-details')
const CustomerPreferencesForm = FormContentBox('customer-preferences')
const AppointmentsForm = FormContentBox('appointments')

const ViewCustomerFormWrapper = ({
  classes,
  initialValues = {},
  isLoadingInitialData,
  onSubmit,
  onSubmitWishlist,
  onSubmitRecommendedProducts,
  onCustomerLoyaltySubmit,
  onCommunicationSubmit,
  onAppCommunicationsSubmit,
  onSubmitAppointments,
  isCustomerEditable,
  onCustomerPreferencesSubmit,
  waitlistInitialValues,
  onSubmitWaitlist,
  customerFirstName,
  onActionsClick,
  exit,
  appointments,
  ...props
}) => {
  const { firstName, lastName, anonymised } = initialValues || {}
  const fullName = getCustomerFullName({ firstName, lastName, anonymised })
  const layout = getAppConfig('CUSTOMER_MANAGEMENT', 'viewCustomerScreenLayout')
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar onClick={exit} />
        )}
        centerContent={(
          <H2 value={_.toUpper(fullName)} />
        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredFollowButtonContainer}>
          <div className={classes.buttonsContainer}>
            <TopRightButton onClick={onActionsClick}>{translations('Actions')}</TopRightButton>
          </div>
          <RequiredTextMessage />
        </div>

        {layout.map(item => {
          if (!item.enabled) return null

          switch (item.type) {
            case 'PERSONAL_DETAILS':
              return (<WrappedFormContentBox
                component={PersonalDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onSubmit}
                schema={schema.personal.schema.map(item => ({ ...item, props: { ...item.props, label: translations(item.props.label) } }))}
                layout={schema.personal.layout}
                boxName={translations('Personal Details')}
                formId='personal-details'
                editable={isCustomerEditable && item.editable}
              />)
             case 'ADDRESS_DETAILS':
              return (<WrappedFormContentBox
                component={AddressDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onSubmit}
                schema={schema.address.schema.map(item => ({ ...item, props: { ...item.props, label: translations(item.props.label) } }))}
                layout={schema.address.layout}
                boxName={translations('Address book')}
                formId='address-book'
                editable={isCustomerEditable && item.editable}
              />)
            case 'CUSTOMER_PREFERENCES':
              return (schema.customer.schema) && (<WrappedFormContentBox
                component={CustomerPreferencesForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onCustomerPreferencesSubmit}
                schema={schema.customer.schema.map(item => ({ ...item, props: { ...item.props, label: translations(item.props.label) } }))}
                layout={schema.customer.layout}
                boxName={translations(item.heading)}
                formId='customer-details'
                editable={isCustomerEditable && item.editable}
              />)
            case 'OTHER':
              return (<WrappedFormContentBox
                component={CustomerDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onCustomerPreferencesSubmit}
                schema={[
                  {
                    id: 'loyaltyType',
                    field: 'Input',
                    props: {
                      label: translations('LOCCI STATUS'),
                      name: 'loyaltyType'
                    }
                  },
                  {
                    id: 'toNextTier',
                    field: 'Input',
                    props: {
                      label: translations('Remaining to next tier'),
                      name: 'toNextTier',
                      editable: false
                    }
                  },
                  {
                    id: 'totalSpend',
                    field: 'Input',
                    props: {
                      label: translations('Total spend'),
                      name: 'totalSpend',
                      editable: false
                    }
                  },
                  {
                    id: 'externalCustomerId',
                    field: 'Input',
                    props: {
                      label: translations('Retail ID'),
                      name: 'externalCustomerId',
                      editable: false
                    }
                  }
                ]}
                layout={[]}
                boxName={translations(item.heading)}
                formId='customer-other'
                editable={isCustomerEditable && item.editable}
              />)
            case 'NOTES':
              return (<LatestNote customer={initialValues} editable={isCustomerEditable && item.editable} />)
            case 'APPOINTMENTS_LISTING':
              return (<AppointmentsList appointments={appointments} noResultsText='No appointments' />)
            case 'LOYALTY':
              return (<Loyalty onSubmit={onCustomerLoyaltySubmit} initialValues={initialValues} editable={isCustomerEditable && item.editable} />)
            case 'COMMS_OPTIONS':
              return (<CommunicationOptions
                boxName={translations('Communication Options')}
                formId='communicationOptions'
                initialValues={initialValues}
                onSubmit={onCommunicationSubmit}
                editable={isCustomerEditable && item.editable}
              />)
            case 'APP_COMMS':
              return (<AppCommunications
                boxName={translations('App Communications')}
                formId='appCommunications'
                initialValues={initialValues}
                onSubmit={onAppCommunicationsSubmit}
                editable={isCustomerEditable && item.editable}
              />)
            case 'MESSAGES':
              return (<LatestMessage customer={initialValues} editable={isCustomerEditable && item.editable} />)
            case 'RECOMMENDED_PRODUCTS':
              return (<RecommendedProducts
                boxName={translations(item.heading, { customerFirstName })}
                formId='recommendedProducts'
                initialValues={initialValues}
                onSubmit={onSubmitRecommendedProducts}
                editable={isCustomerEditable && item.editable}
              />)
            case 'WISHLIST':
              return (<Wishlist
                boxName='Wishlist'
                formId='wishlist'
                initialValues={initialValues}
                onSubmit={onSubmitWishlist}
                editable={isCustomerEditable && item.editable}
              />)
            case 'WAITLIST':
              return (<CustomerWaitlist
                boxName='Waitlist'
                formId='waitlist'
                initialValues={waitlistInitialValues}
                onSubmit={onSubmitWaitlist}
                editable={isCustomerEditable && item.editable}
              />)
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

ViewCustomerFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default compose(
  FullScreenProgress,
  withStyles(styles)
)(ViewCustomerFormWrapper)
