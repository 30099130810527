class AppReducers {
  toggleSideMenu = (state, action) => {
    return {
      ...state,
      isMenuOpen: action.isOpen
    }
  }

  toggleBasket = (state, action) => {
    return {
      ...state,
      isBasketOpen: action.isOpen
    }
  }

  toggleCustomerReviewBasket = (state, action) => {
    return {
      ...state,
      isCustomerReviewBasketOpen: action.isOpen
    }
  }

  appReady = (state, action) => {
    return {
      ...state,
      ready: action.ready
    }
  }
  toggleCustomerMode = (state, action) => {
    return {
      ...state,
      customerMode: action.isOn
    }
  }
  onApiFail = (state, action) => {
    return {
      ...state,
      apiFail: true
    }
  }
  onApiDismiss = (state, action) => {
    return {
      ...state,
      apiFail: false
    }
  }

  changeClientBookTab = (state, action) => {
    return {
      ...state,
      clientBookTab: action.clientBookTab
    }
  }

  changeSnapshotMetricTab = (state, action) => {
    return {
      ...state,
      snapshotMetricTab: action.snapshotMetricTab
    }
  }
  changeSnapshotCarouselTab = (state, action) => {
    return {
      ...state,
      snapshotCarouselTab: action.snapshotCarouselTab
    }
  }
  changePollsTab = (state, action) => {
    return {
      ...state,
      pollsTab: action.pollsTab
    }
  }

  editOrder = (state, action) => {
    return {
      ...state,
      isBasketOpen: true
    }
  }

  stopEditingOrder = (state, action) => {
    return {
      ...state,
      isBasketOpen: false
    }
  }

  changeStoreroomKanbanSelectedColumn = (state, action) => {
    return {
      ...state,
      storeroomKanbanSelectedColumn: action.selectedColumn
    }
  }
  changeInspirationsTab = (state, action) => {
    return {
      ...state,
      inspirationsTab: action.tab
    }
  }
  inspirationsReset = (state, action) => {
    return {
      ...state,
      inspirationsTab: 'BRAND_INSPIRATIONS'
    }
  }
  lockScreen = (state, action) => {
    return {
      ...state,
      screenLocked: action.isLocked
    }
  }
  changeLaguage = (state, action) => {
    return {
      ...state,
      appLanguage: action.language
    }
  }
}

export default new AppReducers()
