// this helpers file exports:
// parseOrZero
// getValue
// sumValues
// getItemValue
// makeGroupDecider
// getItemAggregateValuesFactory

import _ from 'lodash'
import moment from 'moment'

import { isPrice, addPrices, getPriceValue } from '../../../helpers/priceUtil'

export const parseOrZero = value => {
  const parsed = parseFloat(value, 10) || 0
  return parsed
}

// basically just to deal with price objects
export const getValue = actualValue => {
  if (isPrice(actualValue)) {
    return getPriceValue(actualValue)
  }
  return actualValue || 0
}

// this copes with adding two objects together for aggregate data
// eg:
// { id1: { total: 10, count: 2 } } + { id1: { total: 25, count: 3 } }
// = { id1: { total: 35, count: 5 }}
// this is needed for customer category spend & product spend
export const sumValues = (a, b) => {
  if (!a && !b) return 0
  if (!a) return b
  if (!b) return a
  if (typeof a === 'string' || typeof b === 'string') {
    sumValues(parseInt(a), parseInt(b))
  } else if (typeof a === typeof b) {
    if (isPrice(a)) {
      return addPrices([a, b])
    } else if (typeof a === 'object') {
      return _.transform(a, (acc, value, key) => {
        if (_.get(value, 'id')) {
          // don't add objects that are clearly instances
          acc[key] = value
        } else {
          acc[key] = sumValues(b[key], value)
        }
        return acc
      })
    } else {
      return a + b
    }
  } else {
    console.log(`can't add two different js types`)
  }
}

export const getItemValue = (item, path, props = {}, noParse) => {
  if (props.timePeriod) {
    path = path.replace('TIME_PERIOD', props.timePeriod)
  }
  const actualValue = _.get(item, path)
  return noParse ? actualValue : getValue(actualValue)
}

// --------

const isBetweensForTypes = {
  number: (a, b) => value => {
    let from = Math.min(a, b)
    let to = Math.max(a, b)
    return value >= from && value < to
  },
  timeUntil: (a, b) => value => {
    // a and b are '1 year' etc
    // value is a datetime value
    return moment(value)
      .isBetween(
        moment().add(...a.split(' ')),
        moment().add(...b.split(' '))
      )
  },
  timeFrom: (a, b) => value => {
    return moment(value)
      .isBetween(
        moment().subtract(...a.split(' ')),
        moment().subtract(...b.split(' '))
      )
  },
  default: () => () => false
}
const makeIsBetween = valueType => {
  return isBetweensForTypes[valueType] || isBetweensForTypes.default
}

// this is memoized by groupBy, since groupBy is determined by a schema
// therefore will stay constant throughout the app
// so we shouldn't be doing unnecessary complex stuff on each render
const getRangeLabel = range => {
  return range.label || `${range.from} - ${range.to}`
}
const _makeGroupDecider = groupBy => {
  const groupDecider = (item, props) => {
    if (groupBy) {
      if (typeof groupBy === 'object') {
        const { path, type: groupingValueType, ranges, groups } = groupBy
        if (path) {
          const groupingValue = getItemValue(item, path, props)
          if (ranges) {
            const isBetween = makeIsBetween(groupingValueType)
            const correctRange = ranges.reduce((result, range, i) => {
              return (
                isBetween(range.from, range.to)(groupingValue)
                ? range
                : result
              )
            }, null)
            if (correctRange) {
              return getRangeLabel(correctRange)
            } else {
              return null
            }
          } else if (groups) {
            return (
              groups.includes(groupingValue)
              ? groupingValue
              : null
            )
          } else {
            return groupingValue
          }
        } else {
          return 'All'
        }
      } else {
        return getItemValue(item, groupBy, props)
      }
    } else {
      return 'All'
    }
  }
  groupDecider.groupNames = (
    groupBy && typeof groupBy === 'object'
    ? (
      groupBy.ranges
      ? groupBy.ranges.map(range => getRangeLabel(range))
      : groupBy.groups || []
    )
    : []
  )
  return groupDecider
}
export const makeGroupDecider = _.memoize(_makeGroupDecider)

// --------

export const getItemAggregateValuesFactory = (props, noParse) => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data } = reportSchema
  const { aggregateFrom } = data

  // this is to cater for aggregateFrom being one path containing
  // an object, or being multiple paths that we have to shape into
  // an object (eg: sales reporting order summart count chart)
  return (
    Array.isArray(aggregateFrom)
    ? item => {
      const values = (
        aggregateFrom
        .map(path => {
          if (typeof path === 'object') {
            // assume path = { path, label }
            if (!path.path) return {}
            const label = path.label || path.path.split('.').slice(-1)
            const value = getItemValue(item, path.path, props, noParse)
            return {
              key: label,
              value
            }
          } else {
            // assume path is string
            return {
              key: path.split('.').slice(-1),
              value: getItemValue(item, path, props, noParse)
            }
          }
        })
        .reduce(
          (acc, { key, value }) => {
            // this is better perf than spreading
            if (key) acc[key] = value
            return acc
          }, {}
        )
      )
      return values
    }
    : item => {
      return getItemValue(item, aggregateFrom, props, noParse)
    }
  )
}
