import React, { Fragment } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'
import EditMode from '../../../../components/EditMode'
import { getCustomerFullName } from '../../../../helpers'

import { translations, salesforceOCAPI } from '../../../../config'

import style from './style'
import BasketSection from './BasketSection'
import ConfirmSection from './ConfirmSection'
import DateSection from './DateSection'

const CheckoutWrapper = props => {
  const {
    classes,
    children,
    title,
    editable,
    submitText,
    onSubmit,
    handleNext,
    invalid,
    displayOrderDate,
    orderDate,
    editMode,
    orderNumber,
    stopEditingOrder,
    details,
    displayBasketSection,
    ...rest
  } = props

  const subheaderText = (
    title ||
    (rest.customer && getCustomerFullName(rest.customer)) ||
    (_.get(details, 'anonymousReason') === 'unassigned' ? translations('Unassigned') : translations('Anonymous'))
  )

  return (
    <Fragment>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={
          subheaderText
            ? <H2
              value={subheaderText}
              className={classes.uppercase}
            />
            : undefined
        }
      />
      {editMode ? <EditMode fill orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} /> : null}
      <div className={classes.container}>
        {
          children
            ? <div className={classes.column}>
              {children}
            </div>
            : null
        }
        <div className={classes.column}>
          {displayBasketSection && (
            <BasketSection
              {...rest}
              editable={editable}
              hideUnpurchasedItems
              hideUndo
            />
          )}
          {displayOrderDate && (!salesforceOCAPI.basketEnabled)
            ? (
              <DateSection
                editable={editable}
                orderDate={orderDate}
              />
            )
            : null
          }
          <ConfirmSection
            {...rest}
            invalid={invalid}
            submitText={submitText}
            handleNext={handleNext}
          />
        </div>
      </div>
    </Fragment>
  )
}

CheckoutWrapper.defaultProps = {
  submitText: translations('Continue')
}

export default withStyles(style)(CheckoutWrapper)
