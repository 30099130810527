import React from 'react'
import PropTypes from 'prop-types'

import Form from '../../../components/Form'
import FormBody from '../../../components/Form/FormBody'
import FormError from '../../../components/Form/FormError'
import FormSubmit from '../../../components/Form/FormSubmit'
import BottomBar from '../../../components/BottomBar'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import H2 from '../../../components/H2'
import storeDetailsSchema from './storeDetailsSchema'
import storeAppointmentsSchema from './storeAppointmentsSchema'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import { useIsValid, useIsPristine } from '../../../hooks/formHooks'

export const formId = 'store-details'

const StoreDetailsFormWrapper = Form(formId)

const StoreDetailsForm = ({
  onSubmit,
  regions,
  catalogues,
  calendars,
  initialValues,
  mode
}) => {
  const { schema: detailsSchema } = storeDetailsSchema({ regions, catalogues })
  const translatedDetailsSchema = detailsSchema.map(item => ({ ...item, props: { ...item.props, label: translations(item.props.label) } }))
  const { schema: appointmentsSchema } = storeAppointmentsSchema({ calendars })
  const translatedAppointmentsSchema = appointmentsSchema.map(item => ({ ...item, props: { ...item.props, label: translations(item.props.label) } }))
  const isValid = useIsValid(formId)
  const isPristine = useIsPristine(formId)

  const getSubmitButtonText = () => {
    switch (mode) {
      case 'edit':
        return translations('Save Store')
      case 'create':
        return translations('Create Store')
      default: 
        return translations('Save')
    }
  }

  return (
    <div>
      <StoreDetailsFormWrapper
        editing
        onSubmit={onSubmit}
        initialValues={initialValues}>
        <ContentBox>
          <ContentBoxHeader>
            <TextContent><H2 value={translations('Details')} /></TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            <FormBody
              editing
              schema={translatedDetailsSchema}
            />
          </ContentBoxBody>
        </ContentBox>
        <ContentBox>
          <ContentBoxHeader>
            <TextContent><H2 value={translations('Appointments')} /></TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            <FormBody
              editing
              schema={translatedAppointmentsSchema}
            />
          </ContentBoxBody>
        </ContentBox>
        <FormError />
        <FormSubmit
          ContainerComponent={BottomBar}
          label={getSubmitButtonText()}
          big
          disabled={isPristine || !isValid}
        />
      </StoreDetailsFormWrapper>
    </div>
  )
}

StoreDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  isFormValid: PropTypes.bool
}

export default StoreDetailsForm
