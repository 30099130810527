import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { push } from 'connected-react-router'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'
import H3 from '../../../../components/H3'
import ResponsiveTable from '../../../../components/ResponsiveTable'
import LoadMoreButton from '../../../../components/LoadMoreButton'
import { colors } from '../../../../config/theme'
import { translations } from '../../../../config'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { actions as LoccitaneCustomerReportingActions, selectors as LoccitaneCustomerReportingSelectors } from '../../../../store/modules/loccitaneCustomerReporting'
import { actions as createMessageActions } from '../../../../store/modules/createMessage'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Dropdown from '../../../../components/Fields/Dropdown'
import Button from '../../../../components/Button'
import modalService from '../../../../services/modalService'
import BirthdayFiltersModal from './BirthdayFiltersModal'
import SelectedFilters from './SelectedFilters'

const TableTitle = styled.h2`
  background-color: ${colors.lightGrey};
  margin: 1rem 0;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
`

const Container = styled.div`
  margin: 1rem;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`

const LoadMoreButtonContainer = styled.div`
  margin: 0 auto;
`

const SortGridContainer = styled(Grid)`
  justify-content: center;
  align-items: end;
  margin-top: 1rem;
`

const SortDropdown = styled(Dropdown)`
  width: 100%
`

const FiltersContainer = styled.div`
  min-height: 24px;
  margin-bottom: 1rem;
`

const HeadlineText = styled(H3)`
  margin: 1rem;
  text-align: center;
`

const LoccitaneCustomerReportingListingScreen = () => {
  const dispatch = useDispatch()
  const alwaysOnFilter = 'order_type_my_store'
  const defaultFilter = 'upcoming_birthdays_full_listing'
  const rosStoreId = useSelector(authSelectors.getUserSelectedStoreId)
  const territoryId = useSelector(authSelectors.getUserSelectedTerritoryId)
  const isInitialLoading = useSelector(LoccitaneCustomerReportingSelectors.isInitialLoading)
  const isLoadingMore = useSelector(LoccitaneCustomerReportingSelectors.isLoadingMore)
  const purchaseHistoryCustomers = useSelector(LoccitaneCustomerReportingSelectors.getPurchaseHistoryCustomers)
  const hasNextPage = useSelector(LoccitaneCustomerReportingSelectors.getPurchaseHistoryHasNextPage)
  const [page, setPage] = useState(1)
  const [orderByValue, setOrderByValue] = useState('birthdate_asc')
  const [resultsOrder, setResultsOrder] = useState({ orderBy: 'birthdate', orderDirection: 'asc' })
  const [filterBy, setFilterBy] = useState(`${alwaysOnFilter},${defaultFilter}`)

  const columns = [
    { Header: translations('Name (email)'), accessor: 'full_name_and_email', onClick: ({ row }) => onNameClick(row), columnMaxWidth: '220px'},
    { Header: translations('Date of Birth'), accessor: 'birthdate' },
    { Header: translations('Last 12M purchase'), accessor: 'last_twelve_months_purchase_formatted' },
    { Header: translations('Last purchase date'), accessor: 'last_purchase_date_formatted' },
    { Header: translations('Last contact date'), accessor: 'last_contact_date', onClick: ({ row }) => onLastContactClick(row), emptyText: translations('No messages') },
    { Header: translations('Message'), accessor: 'rosCustomer.id', onClick: ({ row }) => onMessageClick(row), iconButtonImage: 'messageDarkIcon', iconButtonAriaLabel: 'Send message'}
  ]

  const onMessageClick = useCallback(({ original }) => {
    let customerMarketingChannels = []
  
    const isOptedInSMS = _.some(original.consents, { channel: 'sms', ['opt-in']: true })
    const isOptedInEmail = _.some(original.consents, { channel: 'email', ['opt-in']: true })
    const isOptedInPost = _.some(original.consents, { channel: "postal", ['opt-in']: true })

    if (isOptedInSMS) {
      customerMarketingChannels.push("smsMarketing")
    }
    if (isOptedInEmail) {
      customerMarketingChannels.push("emailMarketing")
    }
    if (isOptedInPost) {
      customerMarketingChannels.push("postMarketing")
    }

    const customer = {
      ...original.rosCustomer,
      telephone: original.mobile_phone,
      customerMarketingChannels
    }

    dispatch(createMessageActions.updateReceipient(customer, { template: 'birthday' }))
  }, [purchaseHistoryCustomers])

  const onLastContactClick = useCallback(({ original }) => {
    dispatch(push(`/customers/${_.get(original, 'rosCustomer.id')}/messages`))
  }, [purchaseHistoryCustomers])

  const onNameClick = useCallback(({ original }) => {
    dispatch(push(`/customers/${_.get(original, 'rosCustomer.id')}`))
  }, [purchaseHistoryCustomers])

  const onNextPageClick = useCallback(() => {
    dispatch(LoccitaneCustomerReportingActions.fetchPurchaseReportingNextPage({ rosStoreId, territoryId, page: page + 1, filterBy, groupBy: 'upcoming_birthdays_full_listing', ...resultsOrder }))
    setPage(page + 1)
  }, [page, resultsOrder, filterBy])

  useEffect(() => {
    dispatch(LoccitaneCustomerReportingActions.fetchPurchaseReporting({ rosStoreId, territoryId, page, filterBy, groupBy: 'upcoming_birthdays_full_listing', ...resultsOrder }))
  }, [resultsOrder, filterBy])

  const handleSortChange = useCallback((e) => {
    setPage(1)
    setOrderByValue(e.target.value)

    switch (e.target.value) {
      case 'birthdate_asc':
        setResultsOrder({ orderBy: 'birthdate', orderDirection: 'asc' })
        break;
      case 'birthdate_desc':
        setResultsOrder({ orderBy: 'birthdate', orderDirection: 'desc' })
        break;
      case 'last_twelve_months_purchase_asc':
        setResultsOrder({ orderBy: 'last_twelve_months_purchase', orderDirection: 'asc' })
        break;
      case 'last_twelve_months_purchase_desc':
        setResultsOrder({ orderBy: 'last_twelve_months_purchase', orderDirection: 'desc' })
        break;
      case 'last_contact_date_asc':
        setResultsOrder({ orderBy: 'last_contact_date', orderDirection: 'asc' })
        break;
      case 'last_contact_date_desc':
        setResultsOrder({ orderBy: 'last_contact_date', orderDirection: 'desc' })
        break;
      case 'last_purchase_date_asc':
        setResultsOrder({ orderBy: 'last_purchase_date', orderDirection: 'asc' })
        break;
      case 'last_purchase_date_desc':
        setResultsOrder({ orderBy: 'last_purchase_date', orderDirection: 'desc' })
        break;
      default:
        console.log('Unknown sort value')
    }
  }, [])

  const addFilter = useCallback((filter) => {
    const filters = _.compact([filter, alwaysOnFilter]).join(',')
    setFilterBy(filters)
    setPage(1)
  }, [filterBy])

  const resetFilters = useCallback(() => {
    setFilterBy(`${defaultFilter},${alwaysOnFilter}`)
    setPage(1)
  }, [filterBy])

  const openFilterModal = useCallback(() => {
    modalService.open({
      component: BirthdayFiltersModal,
      onConfirm: (filter) => {
        modalService.close()
        addFilter(filter)
      },
      currentFilterBy: filterBy
    })
  }, [filterBy])
  
  return (
    <>
      <SubHeader leftContent={(<BackBar />)} centerContent={(<H2 value={translations('Client book graph title - My home store customers - upcoming birthdays')} />)}/>
      <Container>
      <HeadlineText value={translations('Reporting graph - welcome headline', { date: moment().format('MMM Do') })} />
        <SortGridContainer container spacing={2}>
          <Grid item xs={12} sm={4}>
              <Button 
                color='primary'
                fullWidth
                onClick={openFilterModal}
              >{translations('Filter')}</Button>
            </Grid>
          <Grid item xs={12} sm={4}>
            <SortDropdown
              label={translations('Sort')}
             value={orderByValue}
              options={[
                {label: translations('Date of birth (ascending)'), value: 'birthdate_asc'}, 
                {label: translations('Date of birth (descending)'), value: 'birthdate_desc'},
                {label: translations('Last 12m purchase (ascending)'), value: 'last_twelve_months_purchase_asc'}, 
                {label: translations('Last 12m purchase (descending)'), value: 'last_twelve_months_purchase_desc'},
                {label: translations('Last purchase date (ascending)'), value: 'last_purchase_date_asc'},
                {label: translations('Last purchase date (descending)'), value: 'last_purchase_date_desc'},
                {label: translations('Last contact date (ascending)'), value: 'last_contact_date_asc'},
                {label: translations('Last contact date (descending)'), value: 'last_contact_date_desc'}
              ]}
              onChange={handleSortChange}
              noErrorTextLabel
            />
          </Grid>
          <Grid item xs={12}>
            <FiltersContainer>
              <SelectedFilters 
                activeFilters={filterBy}
                handleDeleteFilter={resetFilters}
                noChipFilters={[alwaysOnFilter, defaultFilter]}
                />
            </FiltersContainer>
          </Grid>
        </SortGridContainer>
      </Container>
      {isInitialLoading && (
        <LoadingSpinnerContainer>
          <CircularProgress />
        </LoadingSpinnerContainer>
      )}
      {!isInitialLoading && (
        <>
          {_.map(purchaseHistoryCustomers, (list, i) => {
            return (
              <Container>
                {!_.isEmpty(list) && (
                  <>
                  <TableTitle>{translations(i)} | {(i === 'thisMonth') ? moment().format('MMM') : moment().add(1, 'month').format('MMM') }</TableTitle>
                  <ResponsiveTable
                    columns={columns}
                    data={list}
                  />
                </>
                )}
              </Container>
            )
          })}
          <LoadMoreButtonContainer>
            <LoadMoreButton
              isLoading={isLoadingMore}
              hasMore={hasNextPage}
              loadNext={onNextPageClick}
            />
          </LoadMoreButtonContainer>
        </>
      )}
    </>
  )
}

export default LoccitaneCustomerReportingListingScreen
