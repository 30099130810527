import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import H2 from '../../../../../components/H2'
import Button from '../../../../../components/Button'
import { translations } from '../../../../../config'
import { getCustomerFullName } from '../../../../../helpers'

import OrderInfo from '../OrderInfo'

import style from './style'

class OrderHeader extends Component {
  renderCustomerBar = () => {
    const { order, goToCustomer, classes } = this.props

    const { userId, customerId, customer, orderType, details } = order || {}
    const noUser = !userId
    const noCustomer = (!customerId && (_.isEmpty(customer) || !customer))

    return <div className={classes.customerBar}>
      {
        noCustomer
          ? <H2 value={translations('Unassigned')} />
          : (noCustomer
            ? (
              orderType !== 'storeroom'
                ? (<Fragment>
                  <H2 value={translations('Anonymous').toUpperCase()} />
                  {_.get(details, 'anonymousReason')
                    ? <div>Reason: {_.get(details, 'anonymousReason')}</div>
                    : null
                  }
                </Fragment>)
                : null
            )
            : (
              <Fragment>
                <H2 value={getCustomerFullName(customer).toUpperCase()} />
                <Button onClick={goToCustomer} buttonType='white'>
                  {translations('View Profile')}
                </Button>
              </Fragment>
            )
          )
      }
    </div>

  }

  render () {
    const { orderInfo, classes } = this.props

    return <div className={classes.container}>
      {this.renderCustomerBar()}
      <OrderInfo info={orderInfo} emphasisKey='status' fillSpace />
    </div>
  }
}

export default withStyles(style)(OrderHeader)
