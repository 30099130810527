import React, { useEffect, useState } from 'react'
import Dropdown from '../../../../components/Fields/Dropdown'
import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import _ from 'lodash'

const GridItem = styled(Grid)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const FilterDropdown = styled(Dropdown)`
  width: 100%;
`

const BirthdayFiltersModal = ({ onConfirm, currentFilterBy }) => {
  const [monthFilter, setMonthFilter] = useState()
  const [currentMonthFilter, setCurrentMonthFilter] = useState()

  const monthOptions = [
    {label: translations('All'), value: 'upcoming_birthdays_full_listing'},
    {label: translations('This month'), value: 'upcoming_birthdays_this_month'},
    {label: translations('Next month'), value: 'upcoming_birthdays_next_month'}
  ]

  useEffect(() => {
    if (currentFilterBy) {
      const filters = currentFilterBy.split(',')
      const monthTypes = _.map(monthOptions, (item) => item.value)
      setCurrentMonthFilter(_.find(filters, (item) => _.includes(monthTypes, item)))
    }
  }, [currentFilterBy])

  return (
    <Grid container>
      <GridItem item xs={12}>
        <FilterDropdown
          label={translations('Select month')}
          value={monthFilter || currentMonthFilter}
          options={monthOptions}
          onChange={(e) => setMonthFilter(e.target.value)}
          noErrorTextLabel
        />
      </GridItem>
      <GridItem item xs={12}>
        <Button 
          color='primary'
          fullWidth
          onClick={() => onConfirm(monthFilter)}
        >{translations('Confirm')}</Button>
      </GridItem>
    </Grid>
  )
}

export default BirthdayFiltersModal
