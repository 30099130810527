import { environment } from './config'
import DigitalStoreSDK from '@redant/digital-store-client-sdk-loccitane'

// returns a resolved promise to mock an ajax request
export const createCustomer = data => {
  return Promise.resolve(data)
}

const sdk = new DigitalStoreSDK({
  baseUrl: environment.API_URL
})

window.sdk = sdk

export default sdk
