import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails'
import { formatDate } from '../../../../../helpers'
import { translations } from '../../../../../config'

import OrderHeader from './OrderHeader'

const mapStateToProps = (state) => {
  const order = orderDetailsSelectors.getOrder(state)
  return { order }
}

const formatOrderType = (orderType) => {
  switch (orderType) {
    case 'consultation':
      return 'Consultation'
    case 'storeroom':
      return 'Pick From Store'
    default:
      return 'Transaction'
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    goToCustomer: ({ dispatch, customer = {} }) => () => {
      dispatch(push(`/customers/${customer.id}`))
    }
  }),
  withPropsOnChange(
    ['order'],
    ({ order }) => {
      const orderInfo = [
        ['status', translations(`Order Status - ${_.get(order, 'status', '')}`)],
        ['date', formatDate(_.get(order, 'orderDate'))],
        ['number', _.get(order, 'orderNumber')],
        ['type', formatOrderType(_.get(order, 'orderType'))],
        ['numberOfItems', _.get(order, 'numberOfPurchaseableProducts')],
        ['store', _.get(order, 'storeDetails.name')]
      ]
      return { orderInfo }
    }
  )
)(OrderHeader)
