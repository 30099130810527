import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class LoccitaneCustomerReportingReducers {
  fetchPurchaseReporting = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchPurchaseReportingPending(state, action)
      case SUCCESS:
        return this.fetchPurchaseReportingSuccess(state, action)
      case FAIL:
        return this.fetchPurchaseReportingFail(state, action)
      default:
        return state
    }
  }
  fetchPurchaseReportingPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type,
      result: {}
    }
  }
  fetchPurchaseReportingSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchPurchaseReportingFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }


  fetchPurchaseReportingNextPage = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchPurchaseReportingNextPagePending(state, action)
      case SUCCESS:
        return this.fetchPurchaseReportingNextPageSuccess(state, action)
      case FAIL:
        return this.fetchPurchaseReportingNextPageFail(state, action)
      default:
        return state
    }
  }
  fetchPurchaseReportingNextPagePending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchPurchaseReportingNextPageSuccess = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        purchaseHistoryCustomers: _.reduce(action.result.purchaseHistoryCustomers, (result, value, key) => {
          return {
            ...result,
            [key]: [
              ..._.get(state, `result.purchaseHistoryCustomers.${key}`),
              ..._.get(action, `result.purchaseHistoryCustomers.${key}`)
            ]
          }
        }, {}),
        hasNextPage: action.result.hasNextPage
      },
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchPurchaseReportingNextPageFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }


  fetchBirthdayReporting = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchBirthdayReportingPending(state, action)
      case SUCCESS:
        return this.fetchBirthdayReportingSuccess(state, action)
      case FAIL:
        return this.fetchBirthdayReportingFail(state, action)
      default:
        return state
    }
  }
  fetchBirthdayReportingPending = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        counts: {
          ..._.get(state, 'result.counts', {}),
          birthdayCounts: {
            status: PENDING,
            action: action.type
          }
        }
      }
    }
  }
  fetchBirthdayReportingSuccess = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        counts: {
          ..._.get(state, 'result.counts', {}),
          birthdayCounts: {
            ...action.result,
            status: SUCCESS,
            action: undefined,
            error: undefined
          }
        }
      }
    }
  }
  fetchBirthdayReportingFail = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        counts: {
          ..._.get(state, 'result.counts', {}),
          birthdayCounts: {
            status: FAIL,
            action: undefined,
            error: action.error.message
          }
        }
      }
    }
  }

  fetchPurchaseCountsReporting = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchPurchaseCountsReportingPending(state, action)
      case SUCCESS:
        return this.fetchPurchaseCountsReportingSuccess(state, action)
      case FAIL:
        return this.fetchPurchaseCountsReportingFail(state, action)
      default:
        return state
    }
  }
  fetchPurchaseCountsReportingPending = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        counts: {
          ..._.get(state, 'result.counts', {}),
          purchaseCounts: {
            status: PENDING,
            action: action.type
          }
        }
      }
    }
  }
  fetchPurchaseCountsReportingSuccess = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        counts: {
          ..._.get(state, 'result.counts', {}),
          purchaseCounts: {
            ...action.result.counts.purchaseCounts,
            status: SUCCESS,
            action: undefined,
            error: undefined
          }
        }
      }
    }
  }
  fetchPurchaseCountsReportingFail = (state, action) => {
    return {
      ...state,
      result: {
        ...state.result,
        counts: {
          ..._.get(state, 'result.counts', {}),
          purchaseCounts: {
            status: FAIL,
            action: undefined,
            error: action.error.message
          }
        }
      }
    }
  }
}

export default new LoccitaneCustomerReportingReducers()
