import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import FormBody from './FormBody'
import * as Fields from '../../EditableFields'
import * as validators from '../../../validators'
import style from './style'
import { translations } from '../../../config'

class FormBodyContainer extends React.Component {
  _mapValidate (validate = [], required = false) {
    // force array
    const validateArr = Array.isArray(validate) ? validate : [validate]
    return required ? [...validateArr, validators.required] : validateArr
  }

  _getMissingFields ({ fields, layout }) {
    const flatLayout = _.chain(layout)
      .flattenDeep()
      .map(field => field.split(':')[0])
      .value()
    return fields
      .filter(field => !flatLayout.includes(field.id))
      .map(field => field.id)
  }

  _buildLayout ({ fields, layout }) {
    // Find missing rows (in schema, not in layout)
    const missingFieldsIds = this._getMissingFields({ fields, layout })
    const fullLayout = layout.concat(missingFieldsIds)
    const {
      globalClasses = {},
      classes: defaultClasses = {},
      initialValues,
      editing,
      formId,
      fullWidthFields,
      className,
      noSpacing
    } = this.props
    // Recursively construct rows
    const buildRows = (rows, type = 'row') => {
      return rows.map(layoutEntry => {
        let rowsContent

        if (Array.isArray(layoutEntry)) {
          // Toggle between row and col
          rowsContent = buildRows(layoutEntry, type === 'row' ? 'col' : 'row')
        } else {
          let [
            rowId = '',
            fieldWidth
          ] = layoutEntry.split(':')
          fieldWidth = parseInt(fieldWidth, 10) || (fullWidthFields ? 12 : 6)

          const {
            Field,
            props,
            classes: fieldClasses = {}
          } = fields.find(({ id }) => id === rowId)

          const validate = this._mapValidate(props.validate, props.required)
          const label = props.label
          const formControlClass = classNames(
            defaultClasses.fieldWrapper,
            globalClasses.fieldWrapper,
            fieldClasses.fieldWrapper
          )
          const fieldClassName = classNames(
            defaultClasses.field,
            globalClasses.field,
            fieldClasses.field
          )
          rowsContent = (
            <Grid item xs={12} sm={fieldWidth} key={rowId}>
              <FormControl
                key={rowId}
                className={formControlClass}
                required={props.required}
              >
                <Field
                  {...props}
                  validate={validate}
                  className={fieldClassName}
                  editing={editing}
                  displayValue={_.get(initialValues, props.name)}
                  label={translations(label)}
                  formId={formId}
                />
              </FormControl>
            </Grid>
          )
        }

        return rowsContent
      })
    }

    // Build rows
    return <Grid
      container
      spacing={!noSpacing && 2}
      className={className}
    >
      {buildRows(fullLayout)}
    </Grid>
  }

  _parseSchema () {
    const { schema = {}, layout = [] } = this.props

    const translatedSchema = schema.map(item => {
      const translatedOptions = _.get(item, 'props.options', []).map(option => {
        return typeof option === 'object'
          ? _.has(option, 'label')
            ? { ...option, label: translations(option.label) }
            : _.has(option, '0.label') // handle store screen specific format
              ? [{ ...option, label: translations(option[0].label) }]
              : option
          : translations(option)
      })
      return (translatedOptions.length)
        ? { ...item, props: { ...item.props, options: translatedOptions } }
        : { ...item }
    })

    // Attach field to schema
    const fields = translatedSchema.map((schemaItem) => ({
      ...schemaItem,
      Field: Fields[schemaItem.field] || schemaItem.component || (
        () => (<div>{`FIELD NOT FOUND FOR: ${schemaItem.id}`}</div>)
      )
    }))

    // Produce layout
    return this._buildLayout({ fields, layout })
  }

  _generateForm () {
    if (!this.props.schema) {
      const { children } = this.props
      return children || null
    } else {
      return this._parseSchema()
    }
  }

  shouldComponentUpdate (nextProps) {
    const corePropsChanged = (
      nextProps.schema !== this.props.schema ||
      nextProps.layout !== this.props.layout ||
      nextProps.editing !== this.props.editing ||
      !(_.isEqual(nextProps.initialValues, this.props.initialValues)) ||
      !(_.isEqual(nextProps.extraProps, this.props.extraProps)) ||
      nextProps.children !== this.props.children ||
      nextProps.className !== this.props.className
    )
    return corePropsChanged
  }

  render () {
    return (
      <FormBody
        {...this.props}
        children={this._generateForm()}
      />
    )
  }
}

FormBodyContainer.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    field: PropTypes.string.isRequired,
    props: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    classes: PropTypes.shape({
      fieldWrapper: PropTypes.string,
      field: PropTypes.string
    })
  })),
  layout: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string
    ])),
    PropTypes.string
  ])),
  globalClasses: PropTypes.shape({
    fieldWrapper: PropTypes.string,
    field: PropTypes.string,
    col: PropTypes.string,
    row: PropTypes.string
  }),
  editing: PropTypes.bool
}

export default withStyles(style)(FormBodyContainer)
