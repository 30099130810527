import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import { translations } from '../../../../../config'
import modalService from '../../../../../services/modalService'
import FormModal from '../../../../../components/FormModal'
import { actions as currentOrderActions, selectors as currentOrderSelectors, constants as currentOrderConstants } from '../../../../../store/modules/currentOrder'
import { actions as orderDetailsActions, selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails'
import { actions as rolesActions, selectors as rolesSelectors } from '../../../../../store/modules/roles'
import { calculateProductsTotal, multiplyPrice, getGroupKeyFromProduct } from '@redant/digital-store-prices-loccitane'

import currencyFormatter from '../../../../../formatters/currencyFormatter'

import UsersSearchScreen from '../../../../Users/UsersSearchScreen'
import ChangeCustomer from '../ChangeCustomer'

const _changeUser = ({ dispatch, rolesStoreIsNotLoaded, rolesStoreHasError, order }) => {
  if (rolesStoreIsNotLoaded || rolesStoreHasError) {
    dispatch(rolesActions.fetchAllRoles())
  }
  const openModal = (open = false) => {
    modalService.open({
      component: UsersSearchScreen,
      disabledUserId: _.get(order, 'user.id'),
      hideBottomBar: true,
      fullScreen: true,
      inModal: true,
      onUserClick: user => {
        const userId = user.id
        const id = order.id

        const userName = user.firstName + ' ' + user.lastName

        modalService.action({
          title: translations('Reassign User Title'),
          text: translations('Reassign User Text', { userName }),
          actions: [
            {
              success: true,
              text: translations('Confirm'),
              onClick: () => dispatch(orderDetailsActions.reassignUser({ userId, id })),
              primary: true
            },
            {
              text: translations('Cancel'),
              onClick: () => openModal(true)
            }
          ]
        })
      },
      onBackClick: () => modalService.close()
    })
  }
  openModal()
}

const _changeCustomer = ({ dispatch, order }) => {
  modalService.open({
    component: ChangeCustomer,
    fullScreen: true,
    orderId: order.id,
    userId: order.userId,
    onBackClick: () => modalService.close(),
    onCustomerClick: (customer) => {
      const { firstName, lastName } = customer
      const userName = `${firstName} ${lastName}`

      modalService.action({
        title: translations('Reassign User Title'),
        text: translations('Reassign User Text', { userName }),
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => dispatch(orderDetailsActions.reassignCustomer({ id: order.id, customer })),
            primary: true
          },
          {
            text: translations('Cancel'),
            onClick: () => modalService.close()
          }
        ]
      })
    }
  })
}

export const withOrderActionHandlers = compose(
  withHandlers({
    reassignOrder: ({ dispatch, rolesStoreIsNotLoaded, rolesStoreHasError, order, canReassignOrder, canReassignCustomer }) => () => {
      modalService.action({
        title: translations('Re-assign order'),
        text: translations('Please choose the type of re-assignment'),
        actions: [
          {
            success: true,
            text: translations('Change user'),
            primary: true,
            disabled: !canReassignOrder,
            onClick: () => _changeUser({ dispatch, rolesStoreIsNotLoaded, rolesStoreHasError, order })
          },
          {
            text: translations('Change Customer'),
            primary: true,
            success: true,
            disabled: !canReassignCustomer,
            onClick: () => _changeCustomer({ dispatch, order })
          }
        ]
      })
    },
    editOrder: ({ dispatch }) => () => {
      dispatch(currentOrderActions.editOrder())
    },
    refundOrder: ({ dispatch, order, orderHasOneOrManyRefundedProducts }) => () => {
      const id = _.get(order, 'id')
      const customerId = _.get(order, 'customerId')
      const userId = _.get(order, 'userId')
      const refundProducts = _.chain(order)
        .get('products')
        .reject(orderProduct => orderProduct.hasBeenRefunded)
        .value()
      const productAmount = refundProducts.length
      const refundTotalAmount = calculateProductsTotal(refundProducts)
      modalService.action({
        title: translations('REFUND ORDER'),
        text: translations('Would you like to refund part of this order, or the whole order?'),
        actions: [
          {
            success: true,
            text: translations('Partial'),
            primary: true,
            disabled: false,
            onClick: () => dispatch(orderDetailsActions.beginRefundPartialOrder({ id, customerId, userId }))
          },
          {
            success: true,
            text: translations('Refund All'),
            primary: true,
            disabled: orderHasOneOrManyRefundedProducts,
            onClick: () => modalService.action({
              title: translations('REFUND ORDER'),
              text: (
                productAmount === '1'
                  ? _.template(translations('Are you sure you would like to refund productAmount item @ refundTotalAmount'))({ productAmount, refundTotalAmount: currencyFormatter.format(refundTotalAmount) })
                  : _.template(translations('Are you sure you would like to refund productAmount items @ refundTotalAmount'))({ productAmount, refundTotalAmount: currencyFormatter.format(refundTotalAmount) })
              ),
              actions: [
                {
                  success: true,
                  text: translations('Refund'),
                  primary: true,
                  disabled: false,
                  onClick: () => dispatch(orderDetailsActions.refundFullOrder({ id, customerId, userId, refundProducts })) // Full refund
                },
                {
                  success: true,
                  text: translations('CANCEL'),
                  primary: true,
                  disabled: false,
                  onClick: () => _.noop
                }
              ]
            })
          },
          {
            success: true,
            text: translations('CANCEL'),
            primary: true,
            disabled: false,
            onClick: () => _.noop
          }
        ]
      })
    },
    // Partial refund
    performRefund: ({ dispatch, order, orderFormValues }) => () => {
      const id = _.get(order, 'id')
      const customerId = _.get(order, 'customerId')
      const userId = _.get(order, 'userId')
      const refundProducts = _
        .chain(orderFormValues)
        .map((refundProductQuantity, key) => {
          const orderProducts = _.get(order, 'products')
          return _
            .chain(orderProducts)
            .find(product => product.id === key.replace('_quantity', ''))
            .thru(orderProduct => _.times(refundProductQuantity, () => orderProduct))
            .value()
        })
        .flatten()
        .value()
      const productAmount = _.reduce(orderFormValues, (memo, quantity, index) => {
        return (parseInt(memo) + (parseInt(quantity) || 0)).toString()
      }, 0)
      const refundTotalAmount = calculateProductsTotal(refundProducts)
      modalService.action({
        title: translations('REFUND ORDER'),
        text: (
          productAmount === '1'
            ? _.template(translations('Are you sure you would like to refund productAmount item @ refundTotalAmount'))({ productAmount, refundTotalAmount: currencyFormatter.format(refundTotalAmount) })
            : _.template(translations('Are you sure you would like to refund productAmount items @ refundTotalAmount'))({ productAmount, refundTotalAmount: currencyFormatter.format(refundTotalAmount) })
        ),
        actions: [
          {
            success: true,
            text: translations('Refund'),
            primary: true,
            disabled: false,
            onClick: () => dispatch(orderDetailsActions.refundFullOrder({ id, customerId, userId, refundProducts }))
          },
          {
            success: true,
            text: translations('CANCEL'),
            primary: true,
            disabled: false,
            onClick: () => _.noop
          }
        ]
      })
    },
    cancelRefundOrderState: ({ dispatch, order }) => () => {
      const id = _.get(order, 'id')
      const customerId = _.get(order, 'customerId')
      const userId = _.get(order, 'userId')
      dispatch(orderDetailsActions.cancelRefundPartialOrder({ id, customerId, userId }))
    },
    stopEditingOrder: ({ dispatch }) => () => {
      dispatch(currentOrderActions.stopEditingOrder())
    },
    stopReassignCustomer: ({ dispatch }) => () => {
      dispatch({ type: 'STOP_REASSIGN_CUSTOMER' })
    },
    onCancelOrderClick: ({ dispatch, order }) => () => {
      modalService.action({
        title: _.toUpper(translations('Cancel Order Title')),
        text: translations('Cancel Order Text'),
        actions: [
          {
            success: true,
            text: translations('Yes'),
            primary: true,
            disabled: false,
            onClick: () => dispatch(orderDetailsActions.cancelOrder({ id: order.id }))
          },
          {
            success: false,
            text: translations('No'),
            primary: false,
            disabled: false
          }
        ]
      })
    },
    onExchangeOrderClick: ({ dispatch }) => () => {
      dispatch(orderDetailsActions.beginExchangeOrder())
    },
    cancelExchangeOrderState: ({ dispatch }) => () => {
      dispatch(orderDetailsActions.cancelExchangeOrder())
    },
    performExchange: ({ dispatch, order, orderFormValues }) => () => {
      // Construct exchange order
      const getQuanity = (id) => orderFormValues[`${id}_quantity`]
      const exchangeOrder = {
        ...order,
        products: order.products
          // remove all items from order not selected in form
          .filter(product => getQuanity(product.id))
          // Mark all new items as refund
          .map(product => {
            // Inverse the price and convert back to string
            return {
              ...product,
              refund: true,
              price: multiplyPrice(product.price, -1)
            }
          })
          // Duplicate product based on quantity
          .reduce((arr, product) => {
            const quanity = Number(getQuanity(product.id))
            return arr.concat(new Array(quanity).fill(product))
          }, [])
      }

      const EXCHANGE = currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE
      dispatch(currentOrderActions.editOrder({ editType: EXCHANGE, order: exchangeOrder }))
      dispatch(orderDetailsActions.cancelExchangeOrder())
    },
    updateOrder: ({ dispatch, order, orderFormValues }) => (status) => {
      const id = _.get(order, 'id')
      
      // go through the form fields from each product and parse the changes to be made
      // to the order products
      const editedProducts = _.get(orderFormValues, 'products', [])
      const orderProductsWithEdits = _.get(order, 'products', []).map(prod => ({...prod, ...editedProducts.find(edit => edit.id === prod.id) || {}}))
      const newProducts = editedProducts.filter(prod => (orderProductsWithEdits.find(orderProd => prod.id === orderProd.id) === undefined))
      const productUpdates = [...orderProductsWithEdits, ...newProducts]

      const updateOrderParams = { id }
      if (status) updateOrderParams.status = status
      if (productUpdates && productUpdates.length) updateOrderParams.products = productUpdates
      return dispatch(orderDetailsActions.updateOrder(updateOrderParams))
    },
    updateOrderStatus: ({ dispatch, order, orderFormValues }) => (status) => {
      const id = _.get(order, 'id')
      const updateOrderParams = { id }
      if (status) updateOrderParams.status = status
      return dispatch(orderDetailsActions.updateOrder(updateOrderParams))
    },
    moveOrderToBasket: ({ dispatch, order, orderFormValues }) => () => {
      const id = _.get(order, 'id')
      const orderProducts = _.get(order, 'products', [])
      const productsToPutInBasket = _.transform(orderFormValues, (result, value, key) => {
        const [fieldType, orderProductGroupKey, orderProductField] = key.split('|')
        if (fieldType === 'orderProduct' && orderProductField === 'notReturned' && value === true) {
          const orderProductsThatMatchGroupKey = _.filter(orderProducts, (orderProduct) => {
            return getGroupKeyFromProduct(orderProduct) === orderProductGroupKey
          })
          result.push(...orderProductsThatMatchGroupKey)
        }
      }, [])
      return new Promise((resolve, reject) => {
        // if there are some products marked as purchased, add them into basket
        if (productsToPutInBasket.length) {
          dispatch(currentOrderActions.populateOrder({
            products: productsToPutInBasket,
            storeroomOrderId: id
          }))
          resolve()
          // if there aren't bring up a modal asking for confirmation
        } else {
          modalService.action({
            title: translations('Complete Storeroom Order With No Purchases Modal Title'),
            text: translations('Complete Storeroom Order With No Purchases Modal Text'),
            actions: [
              {
                text: translations('Confirm'),
                primary: true,
                success: true,
                onClick: resolve
              },
              {
                text: translations('Cancel'),
                onClick: reject
              }
            ]
          })
        }
      })
    },
    payForOrder: ({ dispatch, order, orderFormValues }) => () => {
      modalService.open({
        component: FormModal,
        formId: 'payment',
        title: translations('Complete Payment'),
        onSubmit: (formValues) => {
          dispatch(orderDetailsActions.updateOrder({
            id: order.id,
            status: 'paid',
            details: { ...order.details, paymentRef: formValues.payref }
          }))
          modalService.close()
        },
        schema: [{
          id: 'payref',
          field: 'Input',
          props: {
            label: translations('Please enter the payment reference number'),
            name: 'payref',
            multiline: false,
            required: true
          }
        }]
      })
    }
  })
)
