import _ from 'lodash'
import { promiseReducer } from '../../util'

class OrderDetailsReducers {
  fetchOrder = promiseReducer(
    (state, action) => ({
      ...state,
      order: {
        ...action.result,
        // having a value called 'store' in this redux module causes a js error ('store.getState is not a function')
        // so for now am re-naming this value to storeDetails 
        store: undefined,
        storeDetails: _.get(action, 'result.store')
      }
    })
  )
  reassignUser = promiseReducer(
    (state, action) => ({
      ...state,
      order: {
        // having a value called 'store' in this redux module causes a js error ('store.getState is not a function')
        // so for now am re-naming this value to storeDetails 
        store: undefined,
        storeDetails: _.get(action, 'result.store')
      }
    })
  )
  reassignCustomer = promiseReducer(
    (state, action) => ({
      ...state,
      order: {
        // having a value called 'store' in this redux module causes a js error ('store.getState is not a function')
        // so for now am re-naming this value to storeDetails 
        store: undefined,
        storeDetails: _.get(action, 'result.store')
      }
    })
  )
  beginRefundPartialOrder = (state, action) => ({
    ...state,
    orderState: 'PARTIAL_REFUND'
  })
  beginRefundPartialOrder = (state, action) => {
    const orderId = _.get(state, 'order.id')
    return ({
      ...state,
      orderState: `PARTIAL_REFUND_${orderId}`
    })
  }
  cancelRefundPartialOrder = (state, action) => ({
    ..._.omit(state, ['orderState'])
  })
  performRefund = (state, action) => ({
    ..._.omit(state, ['orderState'])
  })
  cancelOrder = promiseReducer(
    (state, action) => ({
      ...state,
      order: action.result
    })
  )
  updateOrder = promiseReducer(
    (state, action) => ({
      ...state,
      order: {
        ...state.order,
        ...action.result
      },
      noSpinner: false
    }),
    (state, action) => ({
      ...state,
      noSpinner: action.noSpinner
    })
  )
  beginExchangeOrder = (state, action) => {
    const orderId = _.get(state, 'order.id')
    return ({
      ...state,
      orderState: `EXCHANGE_${orderId}`
    })
  }
  cancelExchangeOrder = (state, action) => ({
    ..._.omit(state, ['orderState'])
  })
}

export default new OrderDetailsReducers()
