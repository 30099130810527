import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import CountChart from '../../../../components/Charts/CountChart'
import { useDispatch, useSelector } from 'react-redux'
import { actions as LoccitaneCustomerReportingActions, selectors as LoccitaneCustomerReportingSelectors } from '../../../../store/modules/loccitaneCustomerReporting'

import {
  selectors as AuthSelectors
} from '../../../../store/modules/auth'

const LoccitaneReportingGraph = ({ variant, data, title, rosStoreId }) => {
  const dispatch = useDispatch()
  const purchaseReprtingCountData = useSelector(LoccitaneCustomerReportingSelectors.getPurchaseReprtingCountData)
  const territoryId = useSelector(AuthSelectors.getUserSelectedTerritoryId)
  const [isLoading, setIsLoading] = useState(false)
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    switch (data.path) {
      case 'purchaseCounts':
        dispatch(LoccitaneCustomerReportingActions.fetchPurchaseCountsReporting({ rosStoreId, omitCustomers: true, territoryId }))
        break
      case 'birthdayCounts':
        dispatch(LoccitaneCustomerReportingActions.fetchBirthdayReporting({ rosStoreId, territoryId }))
        break
      default:
        console.log('Unrecognised path for LoccitaneReportingGraph')
    }
  }, [])

  useEffect(() => {
    const rawChartData = _.get(purchaseReprtingCountData, `${data.path}`)

    const ranges = data.ranges
    const mappedChartData = ranges.map(item => ({
      name: item.label, 
      value: _.get(rawChartData, `${item.key}`, 0), 
      color: '#ffd533'
    }))

    setChartData(mappedChartData)
    setIsLoading(_.get(rawChartData, 'status') === 'PENDING')

  }, [purchaseReprtingCountData])

  switch (variant) {
    case 'COUNT':
      return <CountChart
        data={chartData}
        chartTitle={title}
        loading={isLoading}
      />
    default:
      return null
  }
}

export default LoccitaneReportingGraph
