import { Modules } from '@redant/retailos-ui'

const Filters = Modules.RetailAnalytics.Implementation.Filters

export class LoccitaneAnalyticsFunctions extends Modules.RetailAnalytics.Implementation.RetailAnalyticsFunctions {
  mapStoreFiltersCM (filter) {
    return [
      {
        ...Filters.customerStore,
        filter
      }
    ]
  }

  mapUserFiltersCM (filter) {
    return [
      {
        ...Filters.messageUser,
        filter
      },
      {
        ...Filters.customerUser,
        filter
      }
    ]
  }

  mapDateRangeFiltersCM (filter) {
    return [
      {
        ...Filters.messageDate,
        level: 'days',
        filter
      },
      {
        ...Filters.customerDate,
        level: 'days',
        filter
      }
    ]
  }

  mapTimeFrameFiltersCM (filter) {
    return [
      {
        ...Filters.messageDate,
        ...filter
      },
      {
        ...Filters.customerDate,
        ...filter
      }
    ]
  }

  addFilterMembers (jaql, value) {
    const allowedTables = ['customers', 'messages']
    if (!allowedTables.includes(jaql.table)) {
      return {
        ...jaql,
        filter: {}
      }
    }
    return {
      ...jaql,
      filter: {
        ...jaql.filter,
        members: [value]
      }
    }
  }
}