import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import InfiniteScroll from 'react-infinite-scroller'
import H2 from '../../../../components/H2'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import DatedList from '../../../../components/DatedList'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import style from './style'
import activityTypeSelector from '../../../../components/ActivityContainers/activityTypeSelector'
import ViewCustomerActivityListItem from './ViewCustomerActivityListItem'
import { getCustomerFullName } from '../../../../helpers/getFullName'
import { translations } from '../../../../config'

const ViewCustomerActivityScreen = FullScreenLoader(({
  classes,
  activity,
  hasMore,
  isPending,
  customer,
  fetchNextNotifications
}) => (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={translations('All Activity for {customer}', { customerName: getCustomerFullName(customer) })} />
        )}
      />
      <div className={classes.listContainer}>
        {
          activity.length
            ? (
              <InfiniteScroll
                hasMore={hasMore}
                initialLoad={false}
                loadMore={fetchNextNotifications}
              >
                <DatedList
                  items={activity}
                  ItemComponent={(activity) => {
                    const Container = activityTypeSelector(activity.activityType)
                    const Component = Container(ViewCustomerActivityListItem)
                    return <Component key={activity.id} activity={activity} />
                  }}
                  groupTitleClass={classes.notificationHeaders}
                />
              </InfiniteScroll>
            )
            : (
              <div className={classes.noNotificationsContainer}>{'No activity for customer'}</div>
            )
        }
        {isPending && (
          <div className={classes.moreLoaderWrapper}>
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  ))

export default withStyles(style)(ViewCustomerActivityScreen)
