import React from 'react'

import Editable from '../../../../components/Editable'
import ProductsRouter from '../../../../containers/Products/ProductsRouter'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { SelectProduct } from '../../../../components/EditableFields'
import { translations, recommendedProductsContentOptions } from '../../../../config'
import InspirationsSearchScreen from '../../../Inspirations/InspirationsSearchScreen'

const RecommendedProductsForm = FormContentBox('recommendedProducts')
const editButtonText = RecommendedProductsForm ? 'Add/Edit' : 'Edit'
const WrappedRecommendedProductsForm = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, saveDisabled, editable }) => {
  return (
    <RecommendedProductsForm
      enableReinitialize
      initialValues={initialValues}
      editing={editing}
      editable={editable}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={boxName}
      onSubmit={onSubmit}
      formId={formId}
      saveDisabled={saveDisabled}
      editButtonText={translations(editButtonText)}
    >
      <FormError />
      <FormBody>
        <SelectProduct
          options={recommendedProductsContentOptions}
          label={translations('Recommended products heading')}
          editing={editing}
          name='recommendedProducts'
          productsModalComponent={ProductsRouter}
          inspirationsModalComponent={InspirationsSearchScreen}
        />
      </FormBody>
    </RecommendedProductsForm>
  )
})

export default WrappedRecommendedProductsForm
